@import '../variable.scss';

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 20px;
    background: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 20px 0px 0px 0px;
    p {
      color: #c4c4c4;
      font-size: $font-size-md;
      font-weight: $font-medium;
    }
    .close {
      cursor: pointer;
    }
  }
  .body {
    padding: 18px 20px;
    overflow-y: auto;
    .item-container {
      border: 1px solid rgba(255, 255, 255, 0.05);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px 20px 20px;
      gap: 20px;
      isolation: isolate;
      margin-bottom: 20px;
      cursor: pointer;

      .title-container {
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
        padding: 0px 0px 10px;
        .title-info {
          svg {
            margin-bottom: -5px;
            margin-right: 5px;
          }
          span {
            font-weight: $font-medium;
            font-size: $font-size-sm;
            line-height: 20px;
            color: #ffffff;
          }
        }

        .logo {
          display: flex;
          align-items: center;
          gap: 5px;
          margin: 5px 0;
          img {
            width: 17px;
            height: 17px;
          }
          p {
            font-size: $font-size-xs;
            font-weight: $font-normal;
            color: $secondary-color;
            line-height: 18px;
          }
        }
        .time {
          font-size: $font-size-xs;
          font-weight: $font-normal;
          color: $secondary-color;
          line-height: 18px;
          text-transform: uppercase;
        }
      }

      .content-container {
        .greeting {
          display: flex;
          align-items: center;
          gap: 5px;
          p {
            font-size: $font-size-sm;
            font-weight: $font-medium;
            line-height: 20px;
          }
        }

        .info {
          margin-top: 10px;
          svg {
            margin-bottom: -5px;
            margin-right: 5px;
          }
          span {
            font-weight: $font-medium;
            font-size: $font-size-sm;
            line-height: 20px;
            color: $secondary-color;
          }
        }

        .link-to {
          padding: 10px;
          background: rgba(255, 255, 255, 0.05);
          border-radius: 5px;
          margin-top: 15px;
          cursor: pointer;

          font-size: $font-size-xs;
          font-weight: $font-normal;
          line-height: 18px;
          .click-here {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
          }
          .join-us {
            p {
              background: linear-gradient(90deg, #ffe036 0%, #fbc006 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
              text-align: center;
            }
          }
        }
      }
    }

    .seen {
      background: linear-gradient(90deg, rgba(255, 224, 54, 0.05) 0%, rgba(251, 192, 6, 0.05) 100%);
    }
    .unseen {
      background: #1f1f1f;
    }
  }
}
