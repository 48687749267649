@import '../../variable.scss';
.title {
  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height: 21px;
  color: #fff;
  display: flex;
  align-items: center;
  line-height: 1;
  margin-top: 10px;
  svg {
    cursor: pointer;
    transform: rotate(180deg);
    margin-right: 17px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 730px;
  overflow-y: auto;
  cursor: auto;
  background-color: #2c2c2c;
  .avatar {
    width: 170px;
    height: 170px;
    border: 10px solid #434343;
    border-radius: 20px;
    position: relative;
    background-color: #434343;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 10px;
      overflow: hidden;
    }
    .edit-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .edit-button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        padding: 10px;
        font-size: 16px;
        background: rgba(0, 0, 0, 0.6);
        svg {
          margin-right: 5px;
        }
      }
    }
  }
  .user-name {
    font-size: 16px;
    font-family: 'Rubik-Regular';
    margin-top: 40px;
    color: #a2a3a4;
    line-height: 19px;
    width: 100%;
    input {
      height: 45px;
      width: 100%;
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid #343434;
      border-radius: 5px;
      font-size: 14px;
      color: #fff;
      padding: 11px 23px;
      margin-top: 10px;
      &:focus {
        outline: none;
      }
    }
    .warning {
      margin-top: 10px;
      color: #a2a3a4;
      font-size: 14px;
      line-height: 17px;
      font-family: 'Rubik-Regular';
    }
  }
  .modify {
    margin: 40px 0;
    color: #222222;
    font-family: 'Rubik-Medium';
    text-align: center;
    div {
      width: 166px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $background-gradient-gold;
      border-radius: 20px;
      cursor: pointer;
      &.disable {
        opacity: 0.5;
      }
    }
  }
  .error {
    margin-top: 10px;
    font-size: $font-size-sm;
    color: rgb(224, 83, 83);
  }
}
@media screen and (max-width: $breakpoint-mobile) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    padding: 10px;
    overflow-y: auto;
  }
}
