@import '../../variable.scss';
.currency {
  width: 16px;
  height: 16px;
  object-fit: contain;
}
.container {
  max-width: 1350px;
  margin: auto;
  font-size: 14px;
  font-family: 'Rubik-Regular';
  color: #a2a3a4;
  line-height: 20px;

  .amount-wrapper {
    display: flex;
    align-items: center;
    color: #a2a3a4;

    .amount {
      display: flex;
      align-items: center;
      margin-left: 10px;
      line-height: 1;
      svg {
        margin-right: 5px;
      }
    }
  }
  .game {
    display: flex;
    align-items: flex-start;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 40px;
    .setting {
      background: #191919;
      padding: 20px;
      width: 30%;
      min-height: 676px;
      z-index: 130;
      .bank-roll {
        display: flex;
        align-items: center;
        .treasure-wrapper {
          width: 50px;
          height: 50px;
          background: #090909;
          border-radius: 100%;
          padding: 12px;
          img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
          margin-right: 5px;
        }
        .bankroll-text {
          font-size: 12px;
          line-height: 18px;
        }
        .bankroll-number {
          color: white;
          display: flex;
          align-items: center;
          img {
            margin-left: 5px;
            width: 16px;
            height: 16px;
            object-fit: contain;
          }
        }
      }

      .tabs {
        justify-content: flex-start;
        background-color: transparent;
        margin-bottom: 20px;
        .tab {
          width: 50%;
          text-align: center;
          &.disable {
            cursor: not-allowed;
          }
        }
      }
    }
    .dice-bg {
      background-image: url('../../../../public/images/games/classic-dice-bg.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 70%;
      min-height: 676px;
      z-index: 99;
      .dice {
        position: relative;
        min-height: 676px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: rgba(7, 7, 7, 0.91);
        .dice-header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .game-result {
            padding: 10px;
            text-align: center;
            background: rgba(255, 255, 255, 0.05);
            backdrop-filter: blur(10px);
            border-radius: 5px;
            margin-left: 40px;
            text-align: center;
            width: calc(100% - 80px);
          }
          .volume {
            margin-left: 20px;
            cursor: pointer;
          }
        }

        .info {
          position: absolute;
          top: 50px;
          justify-content: center;
          div {
            margin: 0 10px;
            justify-content: flex-start;
            width: fit-content;
            svg {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    width: 100%;
    &.recent-list {
      justify-content: flex-end;
      .recent-result {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16%;
        padding: 10px;
        cursor: pointer;
        margin: 0 2px;
        border-radius: 5px;
        border: 1px solid rgba(255, 255, 255, 0.05);

        &.win {
          background: linear-gradient(270deg, #00be57 0%, #32ff90 101.6%);
          color: #fff;
        }
        &.lose {
          background: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
          color: #a2a3a4;
        }
      }
    }
    .amount {
      display: flex;
      align-items: center;
      svg {
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
  .disable-select-tab {
    opacity: 0.5;
    pointer-events: none;
  }
  .common-button {
    color: #a2a3a4;
    width: fit-content;
    padding: 10px 20px;
    cursor: pointer;
    &.active {
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 5px;
      color: #fff;
      font-family: 'Rubik-Medium';
    }
  }
  .reset-btn {
    cursor: pointer;
    color: #ffe036;
    width: fit-content;
    font-size: 12px;
    line-height: 18px;
  }
  .bet-btn {
    cursor: pointer;
    background: linear-gradient(90deg, #ffe036 0%, #fbc006 100%);
    width: fit-content;
    padding: 10px 30px;
    width: 100%;
    text-align: center;
    border-radius: 7px;
    font-family: 'Rubik-Medium';
    color: black;
    border-radius: 20px;
    margin-top: 20px;
    position: relative;
    font-size: 16px;
    .roll-icon {
      position: absolute;
      left: 21px;
      top: -11px;
    }
    &.disable {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .multiply,
  .division {
    width: fit-content;
    padding: 5px 10px;
    cursor: pointer;
    margin: 0 1px;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .payout-block {
    margin: 0 10px;
    .input-amount {
      .row {
        .amount {
          &:hover {
            background-color: #41434a;
            cursor: pointer;
            color: #fff;
          }
        }
      }
    }
  }
  .payout-wrapper {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(20px);
    border-radius: 5px;
    padding: 20px;
  }
  .input-amount {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 5px 5px 5px 10px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    margin: 3px 0;
    width: 100%;
    height: fit-content;

    &.payout {
      height: 44px;
    }
    &.roll-over {
      input {
        color: #a2a3a4;
        cursor: pointer;
      }
    }
    .amount-wrapper {
      justify-content: flex-end;
      margin: 0;
    }
    .amount {
      cursor: pointer;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 2px;
      margin: 0 1px;
      padding: 10px;
      display: flex;
      align-items: center;
    }
    &.win-amount {
      justify-content: flex-start;
      input {
        height: 40px;
      }
    }

    &.stop-milestone {
      height: 44px;
      justify-content: flex-start;
    }
    .percent {
      font-family: 'Rubik-Medium';
      color: #ffe036;
    }
    .switch-wrapper {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 2px;
      padding: 6px 10px;
      display: flex;
      align-items: center;
      .switch {
        transform: rotate(-90deg);
      }
      .reset-label {
        font-size: 12px;
        line-height: 18px;
        .increase {
          color: white;
        }
      }
    }
    img {
      width: 16px;
      height: 16px;
      object-fit: contain;
      margin-right: 5px;
      min-width: 20px;
      min-height: 20px;
    }
    input {
      background: transparent;
      color: #fff;
      border: none;
      font-family: 'Rubik-Medium';
      margin-right: 5px;
      width: 50%;
      &:focus {
        outline: none;
      }
    }
    .range {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 1px;
      cursor: pointer;
      svg {
        margin-right: 0;
        &:first-child {
          transform: rotate(180deg);
        }
      }
    }
    .quick-action {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 2px;
      color: #a2a3a4;
      width: 36px;
      height: 27px;
      svg {
        min-height: 13px;
      }
      &:hover {
        background-color: #41434a;
      }
    }
  }
  .label {
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    font-family: 'Rubik-Medium';
    margin: 40px 0 20px 0;
  }
  .panel {
    background: #1f1f1f;
    border-radius: 20px;
    width: 100%;
    padding: 10px 20px 20px;
    .tabs {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .tab {
        padding: 30px;
        color: #a2a3a4;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.active {
          color: #ffe036;
          border-bottom: 2px solid #ffe036;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .container {
    .game {
      flex-wrap: wrap;
      .setting {
        width: 100%;
        order: 2;
        min-height: fit-content;
      }
      .dice-bg {
        order: 1;
        width: 100%;
        min-height: 450px;
        .dice {
          .dice-header {
            .row {
              margin: 0;
              width: 90%;
            }
            .volume {
              margin-left: 5px;
            }
          }
          .payout-wrapper {
            flex-wrap: wrap;
            .payout-block {
              width: 100%;
            }
          }
        }
        .info {
          top: 10px;
        }
      }
    }
  }
}
.max-profit {
  white-space: nowrap;
  padding: 10px;
  background-color: #212328;
}
