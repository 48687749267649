@import '../variable.scss';

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  .title {
    font-weight: $font-medium;
    font-size: $font-size-lg;
    line-height: 21px;
  }

  .header-item {
    display: flex;
    align-items: center;
    font-size: 18px;

    .token-img {
      margin-left: 10px;
    }
  }
}

.tabs-container {
  display: flex;
  justify-content: space-between;
  color: $secondary-color;
  width: 500px;
  gap: 10px;
  .tab-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .tab-active {
      color: #ffe036;
    }
    .underline-tab {
      width: 38px;
      height: 2px;
      background: #ffe036;
      border-radius: 14px 14px 0px 0px;
      margin-top: 20px;
    }
  }
}

.table {
  max-width: calc(100vw - 55px);
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 10px;
  .hash-text {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
  }
  .btn-copy {
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .header-container {
    flex-direction: column;
    align-items: flex-start;
    .title {
      margin-bottom: 10px;
    }
  }

  .tabs-container {
    width: 100%;
    .tab-item {
      text-align: center;
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
