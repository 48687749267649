@import './../../../variable.scss';

.root {
  width: 100%;
  background: url('../../../../../public/images/games/crash-game-play-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 20px 20px 0 0;

  .game-info {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .history-container {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      gap: 0px;
      flex-wrap: wrap;

      width: calc(100% - 40px);
      height: 50px;
      overflow: hidden;
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 5px;

      .history-item {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
        padding: 10px;
        font-size: 14px;

        .ball {
          width: 15px;
          height: 15px;
          border-radius: 50px;
        }

        .green-ball {
          background: radial-gradient(50% 50% at 50% 50%, #32d170 0%, #23a454 100%);
        }
        .red-ball {
          background: radial-gradient(50% 50% at 50% 50%, #e64a5b 0%, #ea1930 100%);
        }
        .moon-ball {
          background: linear-gradient(90deg, #ffe036 0%, #fbc006 100%);
        }

        .info {
          .game-id {
            color: $secondary-color;
            font-size: 12px;
          }
          .red {
            background: radial-gradient(50% 50% at 50% 50%, #e64a5b 0%, #ea1930 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
          .green {
            background: linear-gradient(270deg, #00be57 0%, #32ff90 101.6%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
          .moon {
            color: #ffe036;
          }
        }
      }
    }
    .volume {
      margin-left: 20px;
      cursor: pointer;
    }
  }

  .monitor-container {
    width: 100%;
    // height: 458px;
    padding: 40px 60px 20px 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .basically-the-graph {
      position: relative;
      // z-index: 2;
      width: 100%;

      canvas {
        // background: rgba(0, 0, 0, 0.5);
        width: 100% !important;
        height: auto !important;
        background: transparent;
      }
    }
    .custom-spaceship {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      width: 100%;
      height: 100%;
      overflow: hidden;
      text-align: left;
      canvas {
        // width: 100%;
        // height: 100%;
      }
    }

    .animation-fall-down {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      overflow: hidden;
      text-align: left;
      padding: 40px 60px 20px 20px;
      canvas {
        // width: 100%;
        // height: 100%;
      }
    }

    .start-in {
      position: absolute;
      z-index: 5;
      color: $primary-color;
      font-size: 40px;
      font-weight: 500;
      max-width: 300px;
      text-align: center;

      .bang {
        color: #e54a5b;
      }
      .wait-next-round {
        font-size: 32px;
        color: #fbc006;
      }
    }
  }
}

.tab-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin: 10px 0;

  .tab-item {
    color: #a2a3a4;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    width: 100%;
    padding: 10px 20px;
    text-align: center;

    cursor: pointer;
  }

  .tab-item-active {
    background: linear-gradient(270deg, rgba(12, 158, 79, 0.2) 0%, rgba(27, 186, 100, 0.2) 101.6%);
    border-radius: 5px;
    border: 1px solid;
    border-color: #0c9e4f;

    p {
      background: linear-gradient(270deg, #0c9e4f 0%, #1bba64 101.6%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}

.game-controller-container {
  width: 100%;

  .body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px 40px;
    gap: 20px;

    background: #1f1f1f;

    .tab-type-container {
      width: 100%;
      max-width: 261px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;

      .tab-type-item {
        color: #a2a3a4;
        border-radius: 5px;
        width: 100%;
        padding: 10px 40px;
        text-align: center;
      }
      .tab-type-item-active {
        background: rgba(255, 255, 255, 0.05);
        color: #ffffff;
      }
    }

    .form-controller {
      width: 100%;
    }
  }

  .footer {
    background: #1f1f1f;
    border-radius: 0px 0px 20px 20px;
    padding: 20px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footer-left-container {
      display: flex;
      gap: 15px;
      align-items: center;
      color: #a2a3a4;
      .footer-item-left {
        display: flex;
        align-items: center;
        gap: 5px;
        svg {
          path {
            fill: #a2a3a4;
          }
        }
      }
    }

    .footer-right-container {
      display: flex;
      gap: 15px;
      align-items: center;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .root {
    .game-info {
      .history-container {
        gap: 0px;
        .history-item {
          width: 30%;
        }
      }
    }
    .monitor-container {
      padding: 15px 40px 15px 5px;
      .start-in {
        font-size: 30px;
        .wait-next-round {
          font-size: 24px;
          max-width: 220px;
        }
      }
    }
    .game-controller-container {
      .body {
        padding: 20px 10px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
