@import './variable.scss';

.layout-first-root {
  display: flex;
  background: #0c0c0c;

  .layout-second-root {
    width: calc(100% - 268px);

    .layout-third-root {
      width: calc(100%);
      display: flex;
      justify-content: space-between;
      padding: 20px 0 0 20px;
      gap: 25px;

      .layout-fourth-root {
        // padding-right: 25px;

        .body-root {
          min-height: calc(100vh - 95px - 115px - 55px);
        }
      }

      .chat-root {
        position: sticky;
        display: flex;
        right: 0;
        top: 115px;
        height: calc(100vh - 115px);
        background-color: transparent;
        border-radius: 20px 0px 0px 0px;
      }
      .chat-container {
        height: 100%;
        border-radius: 20px 0px 0px 0px;
        background: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .layout-first-root {
    .side-bar {
      display: none;
    }
    .layout-second-root {
      width: 100%;

      .layout-third-root {
        width: 100%;
        display: block;
        padding: 0;

        .layout-fourth-root {
          padding-right: 10px;
          padding: 10px;
        }

        .chat-root {
          display: none;
          background: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
          border-radius: 20px 0px 0px 0px;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
