@import '../../variable.scss';

.container {
  max-width: 1350px;
  margin: auto;
}

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: 320px;
  .banner {
    padding-right: 8px;
    width: 68%;
    height: 100%;
  }
  .top-wining {
    width: 32%;
    padding-left: 8px;
  }
}
.games {
  display: flex;
  align-items: center;
  margin: 23px 0;
  font-size: 16px;
  line-height: 19px;
  font-family: 'Rubik-Medium';
  svg {
    margin-right: 5px;
  }
}

@media (max-width: $breakpoint-mobile) {
  .header {
    // flex-direction: column;
    height: auto;
    .banner {
      height: 200px;
      width: 100%;
      margin-bottom: 20px;
      padding-right: 0;
    }
    .top-wining {
      width: 100%;
      padding-left: 0;
    }
  }
}
