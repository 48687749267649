@import '../../../variable.scss';

.content {
  margin-bottom: 10px;
  padding: 10px;
  height: 50%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 436px;
  height: 640px;
  background-image: url('../../../../../public/images/com-rewards-rules-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .info {
    text-align: center;
    .text-primary {
      color: #32ff90;
      font-weight: 500;
      font-size: 25px;
      line-height: 30px;
    }
  }

  .text-note {
    font-size: 16px;
    color: #a2a3a4;
    padding: 0 40px;
    text-align: center;
  }

  .img-container {
    margin-top: 80px;
  }
}

.title {
  display: flex;
  padding: 0 20px;
  align-items: center;
  font-size: $font-size-lg;
  font-family: 'Rubik-Medium';
  width: 100%;
}

@media screen and (max-width: $breakpoint-mobile) {
  .content {
    width: 100%;
    height: auto;
    margin-bottom: 0px;
    padding: 30px 0;
    gap: 15px;

    .text-note {
      padding: 0 30px;
    }
    .img-container {
      width: 70%;
      margin-top: 30px;
      img {
        width: 100%;
      }
    }
  }
}
