@import '../variable.scss';

.title {
  font-weight: $font-extrabold;
}

.body {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 5px;
  .title {
    color: white;
  }
  ul {
    color: $secondary-color;
    font-size: $font-size-sm;
    list-style: type-of($value: 1);
    margin: 0;
    li {
      margin-bottom: 10px;
    }
  }
  p {
    color: $secondary-color;
    font-size: $font-size-sm;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .body {
    padding: 5px;
    width: 90vw;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
