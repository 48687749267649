@import '../../variable.scss';
.header-title-modal {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-right: 10px;
  .title {
    display: flex;
    padding: 0 20px;
    align-items: center;
    font-size: $font-size-lg;
    font-family: 'Rubik-Medium';
    width: 100%;
    .icon {
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .input-search {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    background: rgba(255, 255, 255, 0.05);
    border: 1.10938px solid #343434;
    border-radius: 5px;
    margin-bottom: 20px;
    .icon {
      margin-top: 3px;
    }
    input {
      padding: 0 10px;
      width: 100%;
      font-size: $font-size-md;
      background: none;
      border: none;
      color: white;
    }
    input:focus {
      outline: none;
    }
  }
}

.modal-assets {
  max-width: 500px;
  .list-assets {
    @include list-default();
    background-color: #2c2c2c;
    width: 400px;
    height: 450px;
    overflow-y: auto;
    .item {
      padding: 10px 20px;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: 'Rubik-Medium';
      cursor: pointer;
      .crypto-title {
        display: flex;
        align-items: center;
        .icon {
          margin-right: 10px;
          width: 24px;
          height: 24px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .price-crypto {
        color: $secondary-color;
        .text-white {
          color: white;
        }
      }
    }
    .item-active {
      border: 1px solid #ffe036;
    }
    .item:hover {
      background: rgba(255, 255, 255, 0.05);
    }
  }
}

.modal-footer {
  padding: 0 20px;
  display: flex;
  .switch {
    display: flex;
    margin-right: 40px;
    .text {
      margin-left: 16px;
      font-size: $font-size-sm;
    }
  }
}

.wallet-desc {
  margin-right: 10px;
  text-align: center;
  .coin-name {
    cursor: pointer;
    display: flex;
    .icon {
      width: 17px;
      height: 17px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .name {
      margin-inline: 5px;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .modal-assets {
    width: 98%;
    .list-assets {
      width: 100%;
    }
  }
}
