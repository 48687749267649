.slider-container {
    width: 200px;
    height: 2.5rem;
    background-color: #212328;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    .min-max-btn {
      background: rgba(255, 255, 255, 0.05);
      font-size: 12px;
      height: 100%;
      width: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;
      cursor: pointer;
      &:active,
      &:hover {
        background: #131313;
        color: #fff;
      }
    }
    .slider {
      -webkit-appearance: none;
      width: 100% !important;
      height: 0.5rem;
      background: #17181b !important;
      outline: none;
      opacity: 0.7;
      -webkit-transition: 0.2s;
      transition: opacity 0.2s;
      &:hover {
        opacity: 1;
      }
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 0.75rem;
        height: 25px;
        background: #cccfd9;
        cursor: pointer;
      }
      &::-moz-range-thumb {
        width: 0.75rem;
        height: 25px;
        background: #cccfd9;
        cursor: pointer;
      }
    }
  }