.title {
  color: #ffe036;
  font-family: 'Rubik-Medium';
  font-size: 20px;
}
.terms {
  max-height: 470px;
  overflow-y: auto;
  margin-bottom: 30px;
  padding-right: 15px;

  .sub-title {
    color: #32ff90;
    font-size: 16px;
    margin: 12px 0;
    font-family: 'Rubik-Medium';
  }
  .content {
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
  }
}
