@import './../../../variable.scss';

.root {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .trenball-container {
      display: flex;
      align-items: center;
      gap: 10px;
      p {
        color: #a2a3a4;
        font-size: 14px;
      }
    }
    .table-container {
      margin-top: 10px;
      font-size: 14px;
    }
  }
}

.col-header-flex {
  display: flex;
  align-items: center;
  // justify-content: center;
  gap: 5px;
  .token {
    width: 15px;
    height: 15px;
  }
  .text-amount {
    color: #fff;
  }
  .win{
    background: linear-gradient(270deg, #0c9e4f 0%, #1bba64 101.6%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .lose{
    background: radial-gradient(50% 50% at 50% 50%, #E64A5B 0%, #EA1930 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
  }
}
.profit-data {
  justify-content: flex-end;
}

.footer-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;

  .footer-item-flex {
    display: flex;
    gap: 5px;
    align-items: center;
    .point-yellow {
      width: 5px;
      height: 5px;
      background-color: #ffe036;
      border-radius: 50px;
    }
  }
  .btn-show-more {
    display: flex;
    gap: 5px;
    align-items: center;
    border: 1px solid #4a4a4a;
    padding: 5px 8px 5px 10px;
    backdrop-filter: blur(10px);
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.05);
    outline: none;

    cursor: pointer;
  }

  .btn-show-less {
    svg {
      transform: rotate(180deg);
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
