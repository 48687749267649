@import '../../../variable.scss';

.content {
  padding: 10px;
  height: 50%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .text {
    font-size: 16px;
    color: #a2a3a4;
  }
}

.title {
  display: flex;
  padding: 0 20px;
  align-items: center;
  font-size: $font-size-lg;
  font-family: 'Rubik-Medium';
  width: 100%;
}

@media screen and (max-width: $breakpoint-mobile) {
  .content {
    gap: 0px;
    .text {
      font-size: 14px;
    }
  }
}
