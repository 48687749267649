@import '../../variable.scss';

.container {
  padding: 30px 20px;
  background-color: #2c2c2c;
  border-radius: 10px;

  .title {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .body {
    max-width: 600px;
    margin: auto;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    padding: 20px;
    width: calc(100vw - 20px);
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
