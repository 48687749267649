@import '../../variable.scss';

.wrapper {
  position: fixed;
  bottom: 0;
  z-index: 40;
  display: none;
  width: 100%;
  background-color: $background-color-primary;
  color: $secondary-color;
  font-size: $font-size-sm;
  padding: 10px 0;
  overflow-y: auto;
  .list-menu {
    @include list-default();
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .notification {
      position: relative;
      .badge {
        position: absolute;
        top: 0;
        right: -1px;
      }
    }
  }
}

@mixin side-menu {
  position: fixed;
  top: 0;
  z-index: 9000;
  width: 100%;
  height: 100vh;
  background-color: $background-color-primary;
  .profile-slide {
    position: relative;
    .icon-closed {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 100;
    }
  }
}

.wrapper-side-menu {
  @include side-menu();
  transform: translateX(100%);
  transition: 0.3s ease-in-out;
}

.wrapper-side-menu-active {
  @include side-menu();
  transform: translateX(0);
  transition: 0.3s ease-in-out;
}

@media (max-width: $breakpoint-mobile) {
  .wrapper {
    display: block;
  }
}
