@import '../../variable.scss';

.table-transaction {
  border: 1px solid #4a4a4a;
  border-radius: 20px;
  font-size: $font-size-sm;
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      text-align: left;
      color: $secondary-color;
      font-family: 'Rubik-Regular';
      tr {
        border-bottom: 1px solid #4a4a4a;
        th {
          padding: 20px;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 20px;
        }
      }
    }
  }
  .no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    justify-content: center;
  }
  .name-crypto {
    display: flex;
    align-items: center;
    font-size: $font-size-md;
    font-family: 'Rubik-Medium';
    .crypto {
      margin-right: 10px;
      width: 24px;
      height: 24px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .time {
    font-family: 'Rubik-Light';
  }
  .button-state {
    padding: 5.5px 12px;
    border-radius: 22px;
    text-transform: capitalize;
  }
  .button-state-completed {
    background: linear-gradient(270deg, #0c9e4f1a 0%, #1bba641a 101.6%);
    color: #0c9e4f;
    border: 1px solid #1bba6433;
  }
  .button-state-invalid,
  .button-state-rejected {
    background: #f828141a;
    border: 1px solid #f8281433;
    color: #f82814;
  }
  .button-state-validating,
  .button-state-processing,
  .button-state-waiting_for_approval {
    background: #ffe0361a;
    border: 1px solid #ffe03633;
    color: #ffe036;
  }
  .column-transaction {
    cursor: pointer;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .amount-withdraw {
    color: #f82814;
  }
  .amount-deposit {
    color: #0c9e4f;
  }
}

.list-tab-category {
  @include list-default();
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  width: 100%;
  li {
    width: 100%;
    padding: 16px 0;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
  }
  .tab-active {
    background: rgba(255, 255, 255, 0.1);
  }
}

@media (max-width: $breakpoint-mobile) {
  .table-transaction {
    overflow-x: auto;
  }

  .list-tab-category {
    flex-wrap: wrap;
    li {
      text-align: left;
      font-size: $font-size-sm;
      padding: 10px;
    }
  }
}
