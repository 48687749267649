@import '../../variable.scss';
.title {
  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height: 21px;
  color: #fff;
  display: flex;
  align-items: center;
  line-height: 1;
  svg {
    cursor: pointer;
    transform: rotate(180deg);
    margin-right: 17px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  width: 500px;
  max-height: 730px;
  cursor: auto;
  background-color: #2c2c2c;
  .avatar {
    height: 275px;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 20px;
    position: relative;
    background: rgba(0, 0, 0, 0.9);
    cursor: pointer;
    .zoom-and-adjust {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 8px;
      left: 0;
      right: 0;
      margin: auto;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 5px;
      height: 40px;
      padding: 10px;
      width: fit-content;
      font-size: 14px;
      line-height: 17px;
      font-family: 'Rubik-Regular';
      svg {
        margin-right: 8px;
      }
    }
    .camera-icon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: fit-content;
      height: fit-content;
      top: 50%;
      left: 50%;
      margin: -1.75rem 0 0 -1.75rem;
      cursor: pointer;
      input {
        position: absolute;
        cursor: pointer;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
  }
  .custom-image {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    height: 60px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 21px;
    .zoom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80%;
      .zoom-icon-wrapper {
        width: 20px;
        height: 20px;
        margin: 0 10px;
      }
      .zoom-out {
        margin-right: 40px;
        cursor: pointer;
      }
      .zoom-in {
        margin-left: 40px;
        cursor: pointer;
      }
    }
    .rotate-image {
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .redo {
        transform: scaleX(-1);
      }
      .undo,
      .redo {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }
  .list-default-image {
    margin-bottom: 20px;
    .title-image {
      margin-bottom: 10px;
      color: #a2a3a4;
    }
    .list-image {
      display: flex;
      justify-content: space-between;
      .item-image {
        width: 67.5px;
        height: 67px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .submit {
    display: flex;
    margin-block: 20px;
    justify-content: center;
    .button-submit {
      background: linear-gradient(90deg, #ffe036 0%, #fbc006 100%);
      border-radius: 20px;
      border: none;
      padding: 10px 26px;
      font-size: 16px;
      font-family: 'Rubik-Medium';
      cursor: pointer;
    }
    .button-submit-disable {
      background: rgb(148, 148, 1);
    }
  }
}
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  border-radius: 5px;
  background: #a2a3a4;
  outline: none;
  // opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 35px;
  height: 20px;
  background: #ffffff;
  border-radius: 2px;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  appearance: none;
  width: 35px;
  height: 20px;
  background: #ffffff;
  border-radius: 2px;
  cursor: pointer;
}

@media (max-width: $breakpoint-mobile) {
  .content {
    width: 100%;
    padding: 10px;
    max-height: 100vh;
    height: 100vh;
    .list-default-image {
      .list-image {
        .item-image {
          width: 57.5px;
          height: 57px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
