@import '../variable.scss';

.root {
  background-color: #323233;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  input {
    background: rgba(118, 118, 128, 0.24);
    border-radius: 10px;
    color: $primary-color;
    border: none;
    outline: none;
    padding: 10px;
  }

  input::placeholder {
    color: #ebebf599;
  }

  .container {
    max-height: 200px;
    width: 310px;
    overflow-y: auto;
    padding-right: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    .gif-item {
      width: max-content;
      height: max-content;
    }
  }

  .img-container {
    width: 100%;
    background-color: #000000;
    display: flex;
    align-items: center;
    img {
      width: 150px;
      margin: auto;
      padding: 5px 0;
    }
  }
}
