@import '../variable.scss';

.root {
  line-height: 0px;
  position: relative;
  display: inline-block;
  .title-container {
    visibility: hidden;
    width: max-content;
    background-color: $background-color;
    color: $secondary-color;
    padding: 15px 10px;
    border-radius: 5px;

    position: absolute;
    top: -35px;
  }
}

.root:hover {
  .title-container {
    visibility: visible;
  }
}
