@import './../../variable.scss';

.root {
  //   background-color: #222222;
  //   padding: 20px;

  .back-btn {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
    padding: 10px 20px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    width: max-content;
    cursor: pointer;
  }

  .introduction {
    background-color: #1b1b1b;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 20px;

    .banner-container {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      .banner-info {
        display: flex;
        gap: 20px;
        line-height: 26px;
        font-size: 22px;
        img {
          border-radius: 20px;
          width: 161px;
          height: 162px;
        }
        .name-date-wrapper {
          .name {
            color: #ffffff;
            font-size: 22px;
          }
          .provider {
            color: #ffffff;
            font-size: 14px;
            line-height: 20px;
            margin: 10px 0;
            span {
              color: #a2a3a4;
            }
          }
          .release-date {
            color: #a2a3a4;
            font-size: 14px;
          }
        }
      }

      .play-now-btn {
        align-self: flex-start;
        background: linear-gradient(90deg, #ffe036 0%, #fbc006 100%);
        border-radius: 20px;
        padding: 10px 25px;
        border: none;
        outline: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        cursor: pointer;
      }
    }

    .game-review-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .title {
        font-weight: 500;
        font-size: $font-size-md;
        line-height: 24px;
        color: $primary-color;
        text-transform: capitalize;
      }
      .content {
        font-weight: 400;
        font-size: $font-size-sm;
        line-height: 20px;
        color: $secondary-color;
        white-space: pre-line;
      }
      .content-less {
        display: block;
        display: -webkit-box;
        max-height: 14px * 20 * 1; /* số dòng hiển thị */
        -webkit-line-clamp: 1; /* số dòng hiển thị */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .show-btn {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #ffe036;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        svg {
          path {
            fill: #ffe036;
          }
        }
      }
      .show-less {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .game-preview-container {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          font-weight: 500;
          font-size: $font-size-md;
          line-height: 24px;
          color: $primary-color;
          text-transform: capitalize;
        }
        .control-carousel {
          display: flex;
          align-items: center;
          gap: 5px;
          // padding-right: 10px;

          .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(255, 255, 255, 0.05);
            border-radius: 2px;
            width: 30px;
            height: 30px;

            cursor: pointer;
          }
        }
      }

      .image-container {
        position: relative;
        padding-bottom: 30px;
      }
    }
  }
  .game-info-container {
    margin-top: 20px;

    .title {
      margin-bottom: 10px;
    }

    .info-content {
      border-radius: 20px;
      overflow: hidden;

      .row {
        display: flex;
        align-items: center;
        .ceil {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 50%;
          background: rgba(255, 255, 255, 0.05);
          margin-right: 2px;
          margin-top: 1px;
          margin-bottom: 1px;
          font-family: 'Rubik-Regular';
          font-size: 14px;
          line-height: 20px;
          padding: 20px;
          &.disable {
            .label,
            .value-number,
            .value-text {
              color: #4a4a4a;
            }
          }
          .label {
            color: #a2a3a4;
          }
          .value-number {
            color: #ffe036;
          }
          .value-text {
            color: #ffffff;
          }
        }
      }
    }
  }

  .about-this-game-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    .title {
      font-weight: 500;
      font-size: $font-size-md;
      line-height: 24px;
      color: $primary-color;
      text-transform: capitalize;
    }
    .content {
      font-weight: 400;
      font-size: $font-size-sm;
      line-height: 20px;
      color: $secondary-color;
      white-space: pre-line;
    }
  }
}

.slide-item-container {
  border: 1px solid #ffd945;
  border-radius: 10px;
  width: 98%;

  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}

.dot-active {
  width: 15px;
  height: 5px;
  background-color: #d9d9d9;
}

.dot-inactive {
  width: 5px;
  height: 5px;
  background-color: #4a4a4a;
}

.dot-custom {
  border-radius: 27px;
  margin: 5px 3px;
}

.slide-item {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: $breakpoint-mobile) {
  .root {
    .introduction {
      padding: 10px;
      gap: 15px;
      .banner-container {
        .banner-info {
          gap: 10px;
          img {
            width: 100px;
            height: 100px;
          }
        }

        .play-now-btn {
          border-radius: 20px;
          padding: 10px;
          font-weight: 500;
          font-size: $font-size-sm;
          line-height: 20px;

          cursor: pointer;
        }
      }

      .game-review-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .title {
          font-weight: 500;
          font-size: $font-size-md;
          line-height: 24px;
          color: $primary-color;
          text-transform: capitalize;
        }
        .content {
          font-weight: 400;
          font-size: $font-size-sm;
          line-height: 20px;
          color: $secondary-color;
          white-space: pre-line;
        }
        .content-less {
          display: block;
          display: -webkit-box;
          max-height: 14px * 20 * 3; /* số dòng hiển thị */
          -webkit-line-clamp: 3; /* số dòng hiển thị */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .show-btn {
          display: flex;
          align-items: center;
          gap: 10px;
          color: #ffe036;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          cursor: pointer;
          svg {
            path {
              fill: #ffe036;
            }
          }
        }
        .show-less {
          svg {
            transform: rotate(180deg);
          }
        }
      }

      .game-preview-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            font-weight: 500;
            font-size: $font-size-md;
            line-height: 24px;
            color: $primary-color;
            text-transform: capitalize;
          }
          .control-carousel {
            display: flex;
            align-items: center;
            gap: 5px;
            // padding-right: 10px;

            .btn {
              display: flex;
              align-items: center;
              justify-content: center;
              background: rgba(255, 255, 255, 0.05);
              border-radius: 2px;
              width: 30px;
              height: 30px;

              cursor: pointer;
            }
          }
        }

        .image-container {
          position: relative;
          padding-bottom: 30px;
        }
      }
    }
    .game-info-container {
      .info-content {
        .row {
          .ceil {
            padding: 5px 8px;
            font-size: $font-size-xs;
          }
        }
      }
    }
  }
  .slide-item-container {
    width: 100%;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
