@import '../../variable.scss';

.enable-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  font-size: $font-size-sm;

  .note-container {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    padding: 10px 20px;
    a {
      color: $primary-color;
      text-decoration: underline;
    }
  }

  .qr-code-scan-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding: 0 20px;
    text-align: center;

    .qr-code-container {
      padding: 10px;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 15px;
      line-height: 0px;

      .qr-code {
        background-color: $primary-color;
        border-radius: 5px;
        padding: 10px;

        .empty {
          width: 130px;
          height: 130px;
        }
      }
    }
  }

  .secret-key-container {
    color: $secondary-color;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .label {
      font-size: $font-size-md;
    }

    .key {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      gap: 10px;
      background: #1f1f1f;
      border-radius: 5px;
      p {
        color: $primary-color;
        font-size: 16px;
      }
      .copy {
        line-height: 0px;
        cursor: pointer;
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.05);
  }

  .verification-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .code-container {
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $secondary-color;
        padding-bottom: 10px;
      }
    }

    .input-form {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: rgba(255, 255, 255, 0.05);
      border: 1.10938px solid #4a4a4a;
      border-radius: 5px;
      padding: 0 10px;

      input {
        background-color: transparent;
        border: none;
        outline: none;
        padding: 15px 10px 15px 0;
        color: $primary-color;
        width: 100%;
      }

      input::placeholder {
        color: $secondary-color;
      }

      .eye-icon {
        cursor: pointer;
      }
    }

    .button-enable {
      background: linear-gradient(90deg, #ffe036 0%, #fbc006 100%);
      border-radius: 20px;
      padding: 10px 60px;
      margin: auto;
      border: 1px solid #ffe036;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
      outline: none;
    }
    .button-enable:disabled {
      cursor: not-allowed;
    }
  }
}

.disable-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  font-size: $font-size-sm;

  .note {
    color: $secondary-color;
    font-size: $font-size-md;
    text-align: center;
  }

  .verification-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
    padding-top: 20px;

    .divider {
      width: 100%;
      height: 1px;
      background: rgba(255, 255, 255, 0.05);
    }
    .code-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $secondary-color;
        padding-bottom: 10px;
      }
    }
    .password-form {
      p {
        color: $secondary-color;
        font-size: $font-size-md;
        padding-bottom: 10px;
      }
      .input-form {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #1f1f1f;
        border-radius: 5px;
        padding: 0 15px;

        input {
          background-color: transparent;
          border: none;
          outline: none;
          padding: 20px 10px 20px 0;
          color: $primary-color;
          width: 100%;
        }

        input::placeholder {
          color: $secondary-color;
        }

        .eye-icon {
          cursor: pointer;
        }
      }
    }

    .button-enable {
      background: linear-gradient(90deg, #ffe036 0%, #fbc006 100%);
      border-radius: 20px;
      padding: 10px 60px;
      margin: auto;
      border: 1px solid #ffe036;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
      outline: none;
    }
    .button-enable:disabled {
      cursor: not-allowed;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .enable-view-container {
    .secret-key-container {
      .key {
        p {
          text-overflow: ellipsis;
          overflow: hidden;
          width: 200px;
          height: 1.2em;
          white-space: nowrap;
        }
      }
    }
    .verification-container {
      gap: 15px;
      .input-form {
        input {
          padding: 10px 5px 10px 0;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
