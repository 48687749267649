@import '../../variable.scss';

.content-text {
  width: 80%;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
  padding: 20px 0px;
  padding-bottom: 50px;
  margin: auto;
  a {
    color: #ffe036;
  }
}
