.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
  max-width: 400px;

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title-header {
      font-size: 25px;
      line-height: 30px;
    }

    .user-identifier {
      margin-top: 5px;
      display: flex;
      font-size: 16px;
      align-items: center;
      svg {
        margin-right: 5px;
      }
      .identifier {
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 2px;
      }
    }
  }

  .referral-container {
    p {
      font-size: 14px;
      color: #a2a3a4;
      padding: 10px 0;
      line-height: 20px;
    }

    input {
      width: 100%;
      padding: 15px;
      border-radius: 6px;
      font-size: 14px;
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid #9495965c;
      color: white;
      &:focus {
        outline: none;
      }
    }
  }

  .button-referral {
    display: flex;
    justify-content: center;

    button {
      background: linear-gradient(90deg, #ffe036 0%, #fbc006 100%);
      padding: 10px;
      border-radius: 20px;
      width: 50%;
      border: 1px solid #ffe036;
      cursor: pointer;
      font-size: 16px;
      font-family: 'Rubik-Medium';
    }
  }
}
