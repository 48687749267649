@import '../variable.scss';

.code-input {
  .code-inputs {
    display: flex;
    align-items: center;
    gap: 10px;
    input {
      height: 60px;
      width: 60px;
      font-size: $font-size-lg;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid #4a4a4a;
      border-radius: 5px;
      // margin-right: 10px;
      color: white;
      text-align: center;
    }

    input:focus {
      outline: 1px solid yellow;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .code-input {
    .code-inputs {
      input {
        // margin-bottom: 10px;
        width: auto;
        height: auto;
        width: 30px;
        height: 30px;
      }
    }
  }
}
