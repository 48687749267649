@import './../../variable.scss';

.container {
  max-width: 1350px;
  margin: auto;
  .game-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    margin-bottom: 10px;

    .game-play-container {
      grid-column: span 3;
    }
    .room-container {
      grid-column: span 2;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
}

@media screen and (max-width: $breakpoint-tablet) {
  .container {
    .game-container {
      grid-template-columns: repeat(2, 1fr);
      .game-play-container {
        grid-column: span 2;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-desktop) {
}
