@import '../../variable.scss';

@mixin font-style($value) {
  line-height: 26px;
  font-family: 'Rubik-#{$value}';
}

.slide {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 100%;
  height: 100%;
}

.wrapper {
  position: relative;
  border-radius: $rounded-md;
  overflow: hidden;
  width: 100%;
  height: 100%;
  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    border-radius: $rounded-md;
  }
  .content {
    position: absolute;
    top: 10%;
    left: 5%;
    max-width: 326px;
    .title {
      font-size: 42px;
      line-height: 45px;
      @include font-style('Medium');
    }
    .desc {
      @include font-style('Medium');
      text-transform: uppercase;
      font-size: 18px;
      margin-block: 20px;
    }
    .house-edge {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .icon {
        padding-right: 10px;
      }
    }
  }

  .action {
    position: absolute;
    bottom: 12%;
    left: 5%;
    .button-play {
      background: $background-gradient-gold;
      border: none;
      cursor: pointer;
      font-size: 16px;
      text-transform: uppercase;
      font-family: 'Rubik-Bold';
      padding: 12px 29px;
      border-radius: 75px;
      box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0.2);
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .slide {
    width: 95vw;
    height: 100%;
  }
  .wrapper {
    .content {
      max-width: 260px;
      .title {
        font-size: 32px;
      }
      .desc {
        font-size: 14px;
        margin-block: 5px;
        line-height: 20px;
      }
      .house-edge {
        display: flex;
        align-items: center;
        margin-top: 0px;
        font-size: 12px;
        .icon {
          padding-right: 10px;
        }
      }
    }
    .action {
      position: absolute;
      bottom: 12%;
      left: 5%;
      .button-play {
        font-size: 12px;
        padding: 10px 27px;
      }
    }
  }
}
