@mixin overlay {
  position: absolute;
  z-index: 100;
}

.wrapper {
  position: relative;
  .dropdown-left {
    @include overlay();
    left: 0;
  }
  .dropdown-right {
    @include overlay();
    right: 0;
  }
  .dropdown-top {
    @include overlay();
    bottom: 100%;
  }
  .dropdown-right {
    @include overlay();
    top: 100%;
  }
}
