@import '../../variable.scss';

.list-tab {
  @include list-default();
  display: flex;
  overflow-x: auto;
  .item-tab {
    display: flex;
    font-size: 16px;
    align-items: center;
    padding: 10px 20px;
    color: $secondary-color;
    font-family: 'Rubik-Medium';
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    border: none;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    .icon {
      margin-right: 10px;
    }
  }
  .item-active {
    color: white;
    background: rgba(255, 255, 255, 0.2);
  }
}

.list-tab {
  ::-webkit-scrollbar {
    height: 2px;
  }
}

@media (max-width: $breakpoint-mobile) {
  .list-tab {
    .item-tab {
      padding: 5px 10px;
      font-size: $font-size-xs;
    }
  }
}
