@import '../../variable.scss';

.list-crypto {
  @include list-default();
  display: flex;
  overflow-x: auto;
  .item-crypto {
    padding: 20px 40px;
    margin-right: 10px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .icon {
      margin-right: 10px;
      width: 24px;
      height: 24px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .item-active {
    background: rgba(255, 255, 255, 0.2);
  }
}

.deposit-currency {
  margin-top: 20px;
  .title-total {
    display: flex;
    justify-content: space-between;
    color: $secondary-color;
    margin-bottom: 12px;
    .link {
      cursor: pointer;
    }
  }
  .input-deposit {
    background: #1f1f1f;
    border: 4px solid rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    padding: 4px;
    display: flex;
    justify-content: space-between;
    .select-deposit {
      width: 214px;
      height: 60px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      border: none;
      cursor: pointer;
      color: $primary-color;
      justify-content: space-between;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 5px;
      .name-deposit {
        display: flex;
        align-items: center;
        font-size: $font-size-lg;
        .icon-crypto {
          margin-right: 10px;
          width: 24px;
          height: 24px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .balance-deposit {
      .content {
        .title {
          color: $secondary-color;
          margin-bottom: 10px;
          font-size: $font-size-sm;
        }

        margin-top: 5px;
        padding: 0 20px;
        text-align: right;
        .balance {
          font-size: $font-size-lg;
        }
      }
    }
  }
}

.header-title-modal {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-right: 10px;
  .title {
    display: flex;
    padding: 0 20px;
    align-items: center;
    font-size: $font-size-lg;
    font-family: 'Rubik-Medium';
    width: 100%;
    .icon {
      margin-right: 10px;
      cursor: pointer;
    }
  }
}

.modal-assets {
  width: 400px;
  .input-search {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    background: rgba(255, 255, 255, 0.05);
    border: 1.10938px solid #343434;
    border-radius: 5px;
    margin-bottom: 20px;
    .icon {
      margin-top: 3px;
    }
    input {
      padding: 0 10px;
      width: 100%;
      font-size: $font-size-md;
      background: none;
      border: none;
      color: white;
    }
    input:focus {
      outline: none;
    }
  }
  .list-assets {
    @include list-default();
    background-color: #2c2c2c;
    width: 100%;
    height: 450px;
    overflow-y: auto;
    .item {
      padding: 10px 20px;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: 'Rubik-Medium';
      cursor: pointer;
      .crypto-title {
        display: flex;
        align-items: center;
        .icon {
          margin-right: 10px;
          width: 24px;
          height: 24px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .price-crypto {
        color: $secondary-color;
        .text-white {
          color: white;
        }
      }
    }
    .item-active {
      border: 1px solid #ffe036;
    }
    .item:hover {
      background: rgba(255, 255, 255, 0.05);
    }
  }
}

.modal-footer {
  padding: 0 20px;
  display: flex;
  .switch {
    display: flex;
    margin-right: 40px;
    .text {
      margin-left: 16px;
      font-size: $font-size-sm;
    }
  }
}

.deposit-address {
  margin-top: 20px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  .text-yellow {
    color: yellow;
  }
  .content {
    text-align: center;
    color: $secondary-color;
    div {
      margin-block: 10px;
    }
    .button-copy {
      text-align: center;
      padding: 10px 0;
      width: 100%;
      color: $secondary-color;
      border: none;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .address-qr {
    background-color: white;
    border: 10px solid #3b3b3b;
    border-radius: 10px;
    width: 145px;
    height: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.notice {
  color: $secondary-color;
  font-size: $font-size-sm;
  margin-top: 10px;
  .text-notice {
    color: white;
    font-family: 'Rubik-Medium';
  }
}

.warning {
  text-align: center;
  margin-top: 40px;
  padding: 20px;
  font-family: 'Rubik-Light';
  border: 1px solid #4a4a4a;
  border-radius: 5px;
  .text-yellow {
    color: yellow;
    font-family: 'Rubik-Medium';
  }
  .enable-2fa {
    cursor: pointer;
  }
}

.enable-twoFA-container {
  max-width: 600px;
  margin: auto;
}

.choose-network {
  width: 100%;
  margin-top: 10px;
  .title {
    margin-bottom: 20px;
    color: $secondary-color;
  }
  .content {
    display: flex;
    justify-content: space-between;
    .slide {
      width: 90%;
    }
    .button-action {
      padding: 10px;
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid #4a4a4a;
      border-radius: 5px;
      cursor: pointer;
    }
    .slide-choose {
      width: auto;
      padding: 12.5px;
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid #4a4a4a;
      border-radius: 5px;
      cursor: pointer;
    }
    .slide-active {
      background: rgba(255, 224, 54, 0.1);
      border: 1px solid #ffe036;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .list-crypto {
    overflow-x: scroll;
    justify-content: flex-start;
    .item-crypto {
      padding: 10px 20px;
    }
  }
  .deposit-currency {
    font-size: $font-size-sm;
    .input-deposit {
      .select-deposit {
        width: 160px;
      }
    }
  }
  .deposit-address {
    .content {
      .title {
        margin-top: 0;
      }
      font-size: $font-size-xs;
    }
    .address-qr {
      margin-top: 20px;
    }
  }
  .choose-network {
    .content {
      .slide {
        width: 70%;
      }
    }
  }
  .modal-footer {
    padding: 0 10px;
  }
  .modal-assets {
    width: 98%;
  }
}
