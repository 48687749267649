.normal-text {
  color: #a2a3a4;
  font-size: 14px;
  line-height: 17px;
  font-family: 'Rubik-Regular';

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  img {
    width: 17px;
  }
}
.amount {
  justify-content: flex-end;
}
.player {
  color: #ffffff;
  font-size: 14px;
  line-height: 17px;
  font-family: 'Rubik-Medium';
}
.profit {
  font-size: 16px;
  line-height: 19px;
  font-family: 'Rubik-Medium';
}
.green {
  background: linear-gradient(270deg, #0c9e4f 0%, #1bba64 101.6%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.red {
  color: #f82814;
}

.footer-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;

  .btn {
    display: flex;
    gap: 5px;
    align-items: center;
    border: 1px solid #4a4a4a;
    padding: 5px 8px 5px 10px;
    backdrop-filter: blur(10px);
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.05);
    outline: none;

    cursor: pointer;
  }

  .btn-show-less {
    svg {
      transform: rotate(180deg);
    }
  }
}
