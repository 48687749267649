@import '../../variable.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .date-picker {
    display: flex;
    align-items: center;
    background: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
    border: 1.10938px solid #343434;
    border-radius: 5px;
    padding: 4px 20px;
    input {
      background: none;
      padding: 10px 0;
      border: none;
      width: 200px;
      font-size: $font-size-sm;
      color: $secondary-color;
    }
    input:focus {
      outline: none;
    }
    .date-icon {
      margin-top: 3px;
    }
  }
}

.content {
  margin-top: 20px;
  width: 100%;
  padding: 20px;
  background: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
  border-radius: 20px;
}
