@import '../../variable.scss';
.link-email-container {
  background: linear-gradient(
    262.28deg,
    rgba(31, 31, 31, 0.8) 1.13%,
    rgba(44, 44, 44, 0.8) 49.35%,
    rgba(37, 37, 37, 0.8) 97.58%
  );
  border-radius: 10px;
  padding: 30px 60px;
  font-family: 'Rubik-Regular';
  font-size: 16px;
  line-height: 24px;
  color: #a2a3a4;
  .error {
    color: #e74c3c;
    font-size: 12px;
    margin: 8px 0;
    line-height: 18px;
  }
  .title {
    text-align: center;
    color: #ffffff;
    font-family: 'Rubik-Medium';
  }
  .description {
    margin-top: 7px;
    text-align: center;
  }
  form {
    input {
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid #343434;
      border-radius: 5px;
      padding: 12px 24px;
      width: 100%;
      height: 45px;
      color: #fff;
      &:focus {
        outline: none;
      }
    }
    .label {
      text-align: left;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .input-email {
      display: flex;
      align-items: center;
      .input-wrapper {
        position: relative;
        width: calc(100% - 124px);
        &.linked {
          width: 100%;
          input {
            border-radius: 5px;
          }
        }
        .verified-icon {
          width: 29px;
          height: 29px;
          right: 10px;
          top: 7px;
          position: absolute;
          object-fit: contain;
        }

        input {
          padding: 12px 124px 12px 24px;
          border-radius: 5px 0 0 5px;
        }
      }
      .send-code-btn {
        margin-left: 4px;
        cursor: pointer;
        height: 45px;
        background: linear-gradient(90deg, #ffe036 0%, #fbc006 100%);
        border-radius: 0px 5px 5px 0px;
        border: none;
        color: #1e1d1d;
        width: fit-content;
        font-size: 14px;
        line-height: 20px;
        font-family: 'Rubik-Medium';
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 23px;
        &.disable {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }
    .resend-code {
      color: #a2a3a4;
      font-size: 14px;
      line-height: 24px;
      margin-top: 12px;
      text-align: center;
      .count-down {
        color: #f8c20d;
      }
    }
    .input-password {
      position: relative;
      input {
        padding: 12px 36px 12px 24px;
      }
      svg {
        position: absolute;
        top: 13px;
        right: 13px;
        cursor: pointer;
      }
    }

    .verification-code {
      padding-top: 30px;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .label {
        margin-bottom: 25px;
      }
    }
    .confirm-btn {
      background: linear-gradient(90deg, #ffe036 0%, #fbc006 100%);
      border-radius: 28px;
      padding: 15px 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 25px auto 0 auto;
      cursor: pointer;
      border: 0;
      font-family: 'Rubik-Medium';
      .text {
        background: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .link-email-container {
    padding: 20px 15px;
  }
}
