@import './../../../variable.scss';

.form-container {
  width: 100%;

  .input-container {
    .amount-label {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
    }
  }

  .tokens {
    width: 16px;
    height: 16px;
  }

  .suffix-group {
    display: flex;
    align-items: center;
    gap: 5px;

    .suffix-btn {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 2px;
      padding: 0px 10px;
      font-size: 14px;
      color: $secondary-color;
      height: 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .arrows {
      line-height: 10px;
      .arrow-up {
        transform: rotate(180deg);
      }
    }
  }

  .bet-container {
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    gap: 5px;
    margin-top: 40px;
    .bet-item-container {
      .label {
        color: $secondary-color;
        line-height: 40px;
        span {
          color: $primary-color;
        }
      }

      .bet-item-card-container {
        cursor: pointer;

        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 10px;
        gap: 10px;

        background: #4a4a4a;
        border-radius: 5px;

        .bet-item-ball {
          width: 20px;
          height: 20px;
          border-radius: 50px;
        }

        p {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .form-container {
    .bet-container {
      margin-top: 20px;
      .bet-item-container {
        .bet-item-card-container {
          flex-direction: column;
          justify-content: center;

          p {
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
