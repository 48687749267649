@import './../variable.scss';

.input-container {
  // min-width: 250px;
  width: 100%;
  background: none;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  padding: 5px 5px 5px 10px;
  .prefix-container {
    line-height: 0px;
  }
  .input {
    outline: none;
    background: none;
    border: none;
    width: 100%;
    // padding: 10px 35px 10px 10px;
    color: #ffffff;
    font-size: 16px;
  }
  .suffix-container {
    align-self: flex-end;
    // line-height: 0px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .input-container {
    // min-width: 150px;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .input-container {
    // min-width: 150px;
  }
}

@media screen and (max-width: $breakpoint-desktop) {
  .input-container {
  }
}
