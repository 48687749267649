@import '../../variable.scss';

.container {
    position: fixed;
    top: 0;
    transition: 0.5s ease-in-out;
    height: 100vh;
    width: 100vw;
    z-index: 999999;
}

.background {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: black;
    opacity: 0;
}

.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 15px;
    background: #1F1F1F;
    border: 1px solid #4A4A4A;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    top: 50%;
    right: 10%;
}

.title {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: "Rubik-Medium";
    width: 100%;
}

.content {
    display: flex;
    gap: 10px;
    position: relative;

    .avatar {
        img {
            width: 72px;
            height: 72px;
            border-radius: 10px;
        }
    }


    .infor-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .nickname {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: $font-medium;
            font-size: $font-size-lg;
            line-height: 28px;
            color: #FFFFFF;

            span {
                color: #9B9B9B;
            }
        }

        .address {
            display: flex;
            gap: 12px;

            text {
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #A2A3A4;
            }

            .icon {
                cursor: pointer;
            }
        }
    }

    .modal-close-button {
        border: none;
        background: none;
        color: #A2A3A4;
        font-size: 14px;
        cursor: pointer;
        position: absolute;
        right: 0;
    }
}



@media screen and (max-width: $breakpoint-mobile) {
    .wrapper {
        left: 20%;

        .content {
            flex-direction: column;
            align-items: center;
            width: 100%;

            .infor-item {
                font-size: 14px;
                align-items: center;

                .nickname {
                    font-size: 14px;
                }
            }
        }
    }
}