.error {
  color: #e74c3c;
}
.container {
  border-radius: 10px;
  background: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
  padding: 20px;
  font-size: 16px;
  line-height: 19px;
  font-family: 'Rubik-Light';

  .form {
    padding: 20px;

    .code-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-bottom: 20px;

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #a2a3a4;
      }
    }

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding: 10px;

      .title {
        font-weight: 600;
      }

      p {
        color: #a2a3a4;
      }
    }

    .input-login {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-top: 25px;

      p {
        color: #a2a3a4;
      }
      .re-login {
        display: flex;
        color: #a2a3a4;
        margin: auto;
        p {
          padding: 0 10px;
        }
      }
      .input-form {
        position: relative;

        input {
          width: 100%;
          padding: 15px 10px;
          color: white;
          border-radius: 5px;
          font-size: 14px;
        }

        .eye-icon {
          position: absolute;
          cursor: pointer;
          top: 20px;
          right: 10px;
          bottom: 10px;
        }
      }
    }

    .underline {
      width: 100%;
      margin: 40px 0;
      padding: 0.1px 0;
      background-color: #a2a3a468;
    }
    .before-verification {
      color: #a2a3a4;
      display: flex;
      justify-content: center;
      p {
        padding: 0px 5px;
        color: #ffe036;
      }
    }
    .verification {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      p {
        color: #a2a3a4;
      }
    }

    .button {
      display: flex;
      justify-content: center;
      margin: 10px;

      button {
        background: linear-gradient(90deg, #ffe036 0%, #fbc006 100%);
        border-radius: 20px;
        padding: 10px 60px;
        margin: auto;
        border: 1px solid #ffe036;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
      }
      button:disabled,
      button[disabled] {
        border: 1px solid #999999;
        background: #cccccc;
        color: #666666;
      }
    }
    .button-readonly {
      display: flex;
      justify-content: center;
      margin: 10px;

      span {
        color: white;
        background: #444444;
        border-radius: 20px;
        padding: 10px 60px;
        margin: auto;
        border: 2px solid #444444;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}
