@import '../variable.scss';

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 460px;
  text-align: center;
  padding-bottom: 10px;

  p {
    font-size: $font-size-lg;
    line-height: 26px;
    text-align: center;
    color: $primary-color;
  }

  a {
    // text-decoration: none;
    color: #ffe036;
    text-decoration: underline;
    -webkit-text-underline-position: under;
    -ms-text-underline-position: below;
    text-underline-position: under;
  }

  button {
    width: 203px;
    height: 49px;
    border: none;
    font-weight: $font-medium;
    font-size: $font-size-md;
    line-height: 19px;

    text-align: center;
    background: linear-gradient(90deg, #ffe036 0%, #fbc006 100%);
    border-radius: 28px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .body {
    width: 100%;
  }
}
