@import '../variable.scss';

.root {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  color: $text-secondary;
  margin-top: 20px;

  .input-current-page {
    width: 30px;
    height: 30px;
    background: rgba(255, 255, 255, 0.05);

    border: 1px solid #505050;
    border-radius: 2px;

    color: $primary-color;

    text-align: center;
    margin: 0 10px;
  }

  .btn-prev-base {
    transform: rotate(90deg);
  }

  .btn-prev {
    margin-left: 10px;
    margin-right: 5px;
  }

  .btn-next {
    transform: rotate(-90deg);
  }

  .btn {
    width: 30px;
    height: 30px;
    outline: none;
    border: none;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 2px;
    // padding: 5px 7px;
    cursor: pointer;
  }

  .btn:disabled {
    opacity: 50;
    cursor: not-allowed;
  }

  .page-item {
    cursor: pointer;
    padding: 0px 10px;
  }
  .page-item-active {
    color: $text-yellow;
  }
}
