@import '../../variable.scss';

// Withdraw address
.withdraw-address {
  margin-top: 20px;
  .title-total {
    display: flex;
    justify-content: space-between;
    color: $secondary-color;
    margin-bottom: 12px;
    .link {
      cursor: pointer;
    }
  }
  .input-withdraw {
    background: #1f1f1f;
    border: 4px solid rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    padding: 4px;
    display: flex;
    .input {
      width: 100%;
      input {
        font-size: $font-size-sm;
        width: 100%;
        padding: 20px 10px;
        height: 100%;
        background: none;
        color: white;
        border: none;
      }
      input:focus {
        outline: none;
      }
    }
  }
}

// Withdraw amount
.withdraw-amount {
  margin-top: 20px;
  .title-total {
    display: flex;
    justify-content: space-between;
    color: $secondary-color;
    margin-bottom: 12px;
  }
  .input-withdraw {
    background: #1f1f1f;
    border: 4px solid rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    padding: 4px;
    display: flex;
    align-items: center;
    .input {
      width: 100%;
      input {
        font-size: $font-size-md;
        font-family: 'Rubik-Medium';
        width: 100%;
        padding: 20px 10px;
        height: 100%;
        background: none;
        color: white;
        border: none;
        &::placeholder {
          font-size: 14px;
          font-family: 'Rubik-Regular';
        }
        &.placeholder {
          font-size: 14px;
          font-family: 'Rubik-Regular';
        }
      }

      input:focus {
        outline: none;
      }
    }
    .button-group {
      display: flex;
      button {
        padding: 15px;
        border-radius: 5px;
        margin-right: 5px;
        border: none;
        color: $secondary-color;
        background: #ffffff1a;
        cursor: pointer;
      }
    }
  }
  .button-group-mobile {
    display: none;
    margin-top: 10px;
    button {
      padding: 15px;
      border-radius: 5px;
      margin-right: 5px;
      border: none;
      color: $secondary-color;
      background: #ffffff1a;
    }
  }
}

// Verification code
.verification-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block: 20px;
  gap: 20px;
  .text-yellow {
    color: yellow;
  }
  .fee {
    color: $secondary-color;
    font-family: 'Rubik-Medium';
    margin-bottom: 20px;
  }
  .label {
    color: $secondary-color;
    margin-bottom: 20px;
  }
  .code-container {
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: $secondary-color;
      padding-bottom: 10px;
    }
  }
  .button-confirm {
    margin-bottom: 20px;
    button {
      cursor: pointer;
      padding: 15px 80px;
      border-radius: 28px;
      background: linear-gradient(90deg, #ffe036 0%, #fbc006 100%);
      border: none;
      font-family: 'Rubik-Medium';
    }
    button[disabled] {
      color: black;
      background: linear-gradient(90deg, #aa9623 0%, #ae8704 100%);
    }
  }
  .note {
    color: $secondary-color;
  }
}

.error-message {
  margin-top: 5px;
  font-size: $font-size-sm;
  color: rgb(255, 120, 120);
}

@media (max-width: $breakpoint-mobile) {
  .withdraw-amount {
    .input-withdraw {
      .button-group {
        display: none;
      }
    }
    .button-group-mobile {
      display: flex;
    }
  }
}
