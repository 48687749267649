@import './../../../variable.scss';

.root {
  position: absolute;
  z-index: 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    color: $primary-color;
    .text {
      background: linear-gradient(90deg, #ffe036 0%, #fbc006 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-weight: 700;
    }
  }

  .payout-container {
    position: relative;
    display: flex;
    justify-content: center;
    .payout {
      position: absolute;
      font-weight: 500;
      font-size: 48px;
      line-height: 57px;
      color: rgba(255, 255, 255, 0.3);
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .root {
    top: 20px;
    .title {
      font-size: 24px;
    }
    .payout-container {
      .payout {
        font-size: 32px;
      }
      img {
        width: 90%;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
