@import '../variable.scss';

.border {
  border: 1px solid rgba(179, 179, 179, 0.1);
}

.border-bottom {
  border-bottom: 1px solid rgba(179, 179, 179, 0.1);
}

.table-root {
  max-width: 100%;
  overflow-x: auto;
  padding-bottom: 10px;
  .table-container {
    width: 100%;
    border-radius: 10px;
    color: $secondary-color;
    .table-header-item {
      padding: 10px;
      font-weight: 400;
      // min-width: 150px;
    }
    .data-item {
      text-align: center;
      padding: 10px;
      font-weight: 400;
    }

    .nodata {
      text-align: center;
      .nodata-item {
        padding: 10px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
