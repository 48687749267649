@import '../../variable.scss';


.table-root {
    background: #181818;
    width: 100%;
    border-radius: 6px;

    .table-list {
        margin-top: 10px;
        border-radius: 6px;
        border: 1px solid #a2a3a4b3;
    }

    .list-login {
        border-radius: 6px;
        margin-top: 30px;
        padding: 10px;
        max-height: 500px;
        overflow-y: scroll;
    }

    .header {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        line-height: 20px;
        padding: 0 20px;
        margin-top: 20px;

        .title {
            color: #a2a3a4;
        }
    }

    .level {
        text-align: left;
        color: #a2a3a4;
        display: flex;
        align-items: center;
        width: 30%;
    }

    .total {
        text-align: left;
        color: #a2a3a4;
        display: flex;
        align-items: center;
        width: 20%;
    }

    .reward {
        text-align: left;
        display: flex;
        gap: 5px;
        align-items: center;
        color: #a2a3a4;
        width: 30%;
    }

    .list {
        font-size: 14px;
        margin-top: 15px;
        margin-bottom: 20px;

        .record {
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 15px;
            padding: 15px 20px;
            margin: 2px 5px;
        }

        .record-color {
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 15px;
            padding: 15px 20px;
            margin: 2px 5px;
            background: linear-gradient(269.96deg, rgba(0, 76, 190, 0) 0.62%, rgba(0, 76, 190, 0.24) 34.81%, rgba(0, 76, 190, 0.47) 80.9%);
            border-radius: 5px;
        }
    }
}

@media screen and (max-width: $breakpoint-desktop) {
    .table-root {
        padding: 0px 10px;
        width: auto;

        .header {
            font-size: 14px;
        }

        .list {
            font-size: 12px;
        }

        .level {
            text-align: center;
            width: auto;
        }

        .total {
            text-align: center;
            width: auto;
        }

        .reward {
            text-align: center;
            width: auto;
        }

    }
}