@import '../variable.scss';

.account {
  padding: 20px;
  border-radius: 20px;
  margin-top: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  background: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
  min-width: 200px;
  .info {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .detail {
      display: flex;
      .desc {
        margin-left: 10px;
      }
    }
  }

  .button-logout {
    background: #ff773c;
    border: none;
    display: flex;
    margin-top: 20px;
    margin: auto;
    padding: 5px 20px;
    border-radius: 20px;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .header-root {
    flex-direction: column;
    gap: 10px;
    padding: 0px 10px;
    flex-wrap: wrap;
    height: 150px;
    justify-content: flex-start;
    .header-logo {
      display: block;
      width: 100px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .header-input-search-container {
      .header-input-search {
        width: auto;
        height: auto;
      }
    }
    .header-group-right {
      display: none;
      .header-group-right-item {
        .message {
          display: none;
        }
      }
    }
    .button-group {
      .message {
        display: none;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
.cash-animation {
  animation: cash 2s ease-in-out;
  position: absolute;
  bottom: -30px;
  opacity: 0;
}
@keyframes cash {
  0% {
    opacity: 0;
    transform: scale(1);
    bottom: -30px;
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
    bottom: 5px;
  }
  100% {
    opacity: 0;
    transform: scale(1);
    bottom: 10px;
  }
}
