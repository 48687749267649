@import '../../../variable.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    width: 100%;

    .rewards-block {
      padding: 14px 24px;
      width: fit-content;
      margin: 60px auto 0 auto;
      background: url('../../../../../public/images/background-text.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      color: #ffffff;
      font-size: 18px;
      line-height: 28px;
      font-family: 'Rubik-Medium';

      svg {
        margin-right: 10px;
      }
    }
  }

  .arrow {
    margin: auto;
    width: 50%;
  }

  .reward-info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    .usd-reward,
    .commission-reward {
      width: 48%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 20px;
      overflow: hidden;

      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 20px;
        background: #1f1f1f;
        width: 100%;

        .title {
          font-size: 25px;
          line-height: 30px;
          text-align: center;
          font-family: 'Rubik-Medium';
          margin-top: 25px;

          .highlight {
            color: #ffe036;
          }
        }

        .rule {
          color: #ffe036;
          font-size: 14px;
          line-height: 20px;
          font-family: 'Rubik-Regular';
          display: flex;
          align-items: center;
          margin-top: 7px;
          cursor: pointer;

          svg {
            width: 16px;
            height: 16px;
          }
        }

        .newly-rewards {
          background: #181818;
          border: 1px solid rgba(255, 255, 255, 0.05);
          border-radius: 10px;
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px 40px;
          width: 100%;

          .rewards-value {
            color: #ffe036;
            font-size: 25px;
            line-height: 30px;
            font-family: 'Rubik-Medium';
          }

          .sub-reward-value {
            color: #ffe036;
            margin-left: 5px;
            font-size: 18px;
            line-height: 28px;
          }

          .label {
            color: #ffffff;
            font-size: 16px;
            line-height: 24px;
            font-family: 'Rubik-Regular';
            margin-top: 5px;
          }

          .highlight {
            color: #ffe036;
          }
        }

        .description {
          margin-top: 15px;
          text-align: center;
          font-size: 14px;
          line-height: 20px;
          font-family: 'Rubik-Regular';
          margin-bottom: 30px;

          .reward-detail {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 5px;

            .sub-reward {
              margin: 0 5px;
              display: flex;
              align-items: center;

              .sub-reward-value {
                color: #ffe036;
                margin-left: 5px;
              }

              svg {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}

.friends {
  background: #181818;
  padding: 40px 20px;
  margin: 0 -20px;
  width: 100%;
  height: 468px;

  .all-friends {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Rubik-Regular';

    svg {
      margin-right: 10px;
    }

    .friend-number {
      margin-left: 10px;
      color: #ffe036;
    }
  }

  .friend-list {
    margin-top: 30px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 14px;
      line-height: 20px;
      padding: 0 20px;

      .title {
        color: #a2a3a4;
      }
    }

    .name {
      width: 40%;
      text-align: left;
    }

    .earned {
      width: 20%;
      text-align: left;
      color: #ffe036;
    }

    .level {
      width: 40%;
      text-align: left;
      display: flex;
      align-items: center;

      .progress-bar {
        margin-right: 10px;
        width: 70px;
        height: 4px;
        border-radius: 5px;
        background: #4a4a4a;

        .exp {
          height: 100%;
          border-radius: 5px;
          background: #ffe036;
        }
      }
    }

    .list {
      margin-top: 15px;
      margin-bottom: 20px;

      .record {
        background: rgba(255, 255, 255, 0.05);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 15px 20px;
        margin: 2px 0;
      }
    }
  }

  .no-data {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #a2a3a4;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    height: calc(100% - 60px);

    svg {
      margin-bottom: 25px;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    .arrow {
      display: none;
    }

    .reward-info {
      margin-top: 20px;

      .usd-reward,
      .commission-reward {
        width: 100%;
      }
    }
  }
}