@import '../../variable.scss';

.list-navigate-page {
  @include list-default;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  gap: 1.25rem 1.25rem;
  padding: 20px 0px;

  .item {
    position: relative;
    cursor: pointer;
    .background {
      img {
        width: 100%;
        max-height: 148px;
        border-radius: 20px;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      .content-text {
        display: flex;
        width: 70%;
        flex-direction: column;
        position: absolute;
        top: 25px;
        left: 20px;
        text-align: start;

        h1 {
          width: 100%;
          font-size: 30px;
          display: flex;
          margin: 10px 0;
          font-weight: 600;
        }
        p {
          display: flex;
          font-size: 18px;
          line-height: 19px;
          width: 100%;
          font-weight: 500;
        }
      }
      .content-img {
        display: flex;
        justify-content: end;
        position: absolute;
        top: 0;
        bottom: 0%;
        left: 25%;
        right: 30px;
        img {
          width: 50%;
          border-radius: 20px;
        }
      }
    }
  }
}

@media (min-width: 1600px) {
}
