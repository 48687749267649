@import '../../variable.scss';
.layout {
  display: flex;
  align-items: flex-start;
  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    background: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
    padding: 20px;
    font-size: 16px;
    line-height: 19px;
    width: 240px;
    position: sticky;
    top: 110px;
    .menu-item {
      padding: 8px 15px;
      color: #a2a3a4;
      cursor: pointer;
      width: 100%;
      opacity: 0.8;
      font-family: 'Rubik-Regular';
      position: relative;
      text-transform: capitalize;
      &.active {
        background: rgba(14, 13, 13, 0.7);
        border-radius: 5px;
        color: white;
        opacity: 1;
        font-family: 'Rubik-Medium';
        .soon:before {
          background-color: yellow;
        }
      }
      .soon:before {
        content: 'Soon';
        position: absolute;
        top: 3px;
        left: 47px;
        font-size: 7px;
        background-color: #7f7f7f;
        border-radius: 5px;
        padding: 0 5px;
        color: black;
      }
    }
  }
  .content {
    margin-left: 20px;
    flex: 1;
  }
}

@media (max-width: $breakpoint-mobile) {
  .layout {
    flex-direction: column;
    .menu {
      margin-bottom: 20px;
      position: static;
      width: 100%;
    }
    .content {
      margin-left: 0;
    }
  }
}
