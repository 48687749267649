@import '../../variable.scss';
.container {
  max-width: 1350px;
  margin: auto;
  font-size: 14px;
  font-family: 'Rubik-Regular';
  color: #a2a3a4;
  line-height: 20px;
  .amount-wrapper {
    display: flex;
    align-items: center;
    color: #a2a3a4;

    .amount {
      display: flex;
      align-items: center;
      margin-left: 10px;
      line-height: 1;
      svg {
        margin-right: 5px;
        cursor: pointer;
      }
      img {
        width: 16px;
        height: 16px;
        object-fit: contain;
        cursor: pointer;
        margin-right: 5px;
      }
    }
  }
  .game {
    display: flex;
    align-items: flex-start;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 40px;
    .setting {
      background: #191919;
      padding: 20px;
      width: 30%;
      min-height: 676px;
      .tabs {
        justify-content: flex-start;
        background-color: transparent;
        margin-bottom: 20px;
        .tab {
          width: 50%;
          text-align: center;
          &.disable {
            cursor: not-allowed;
          }
        }
      }
    }
    .ring-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .total {
        width: 316px;
        height: 316px;
        border-radius: 100%;
        background: rgba(255, 255, 255, 0.05);
        box-shadow: 2px 2px 4px #171717;
        position: absolute;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Rubik-Medium';
        .second-ring {
          position: absolute;
          text-align: center;
          font-family: 'Rubik-Medium';
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25), inset 0px -1px 2px #1f1f1f,
            inset 2px 2px 2px #1a1a1a;
          width: 266px;
          height: 266px;
          border-radius: 100%;
          .total-number {
            display: flex;
            align-items: center;
            img {
              width: 16px;
              height: 16px;
              object-fit: contain;
              margin-left: 5px;
            }
          }
        }
      }
    }
    .ring {
      position: relative;
      width: 70%;
      min-height: 676px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../../../public/images/ring-of-fortune-bg.png');
      .info {
        position: absolute;
        top: 50px;
        justify-content: center;
        div {
          margin: 0 10px;
          justify-content: flex-start;
          width: fit-content;
          svg {
            margin-right: 5px;
          }
        }
      }
      .volume {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
      }
      .recent-list {
        position: absolute;
        right: 40px;
        height: 70%;
        width: 70px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        .result {
          height: 8px;
          border-radius: 3px;
          margin: 7px 0;

          &.X2 {
            background: radial-gradient(
              46.98% 46.67% at 50% 50%,
              #d8d8d8 0%,
              rgba(164, 164, 164, 0.91) 100%
            );
            width: 40px;
          }
          &.X3 {
            width: 50px;
            background: radial-gradient(50% 50% at 50% 50%, #5b9dff 0%, #0081e4 100%);
          }
          &.X6 {
            width: 60px;
            background: radial-gradient(50% 50% at 50% 50%, #e64a5b 0%, #ea1930 100%);
          }
          &.X99 {
            width: 70px;
            background: radial-gradient(50% 50% at 50% 50%, #32d170 0%, #23a454 100%);
          }
        }
      }
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    width: 100%;
  }
  .disable-select-tab {
    opacity: 0.5;
    pointer-events: none;
  }
  .common-button {
    color: #a2a3a4;
    width: fit-content;
    padding: 10px 20px;
    cursor: pointer;
    &.active {
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 5px;
      color: #fff;
      font-family: 'Rubik-Medium';
    }
  }
  .reset-btn {
    cursor: pointer;
    color: #ffe036;
    width: fit-content;
    font-size: 12px;
    line-height: 18px;
  }
  .bet-btn {
    cursor: pointer;
    background: linear-gradient(90deg, #ffe036 0%, #fbc006 100%);
    width: fit-content;
    padding: 10px 30px;
    width: 100%;
    text-align: center;
    border-radius: 7px;
    font-family: 'Rubik-Medium';
    color: black;
    border-radius: 20px;
    margin-top: 20px;
    font-size: 16px;
    &.disable {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .multiply,
  .division {
    width: fit-content;
    padding: 5px 10px;
    cursor: pointer;
    margin: 0 1px;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .input-amount {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 5px 5px 5px 10px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    margin: 3px 0;
    width: 100%;
    height: fit-content;

    &.stop-milestone {
      height: 44px;
      justify-content: flex-start;
    }
    .percent {
      font-family: 'Rubik-Medium';
      color: #ffe036;
    }
    .switch-wrapper {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 2px;
      padding: 6px 10px;
      display: flex;
      align-items: center;
      .switch {
        transform: rotate(-90deg);
      }
      .reset-label {
        font-size: 12px;
        line-height: 18px;
        .increase {
          color: white;
        }
      }
    }
    svg {
      margin-right: 5px;
      min-width: 20px;
      min-height: 20px;
    }
    input {
      background: transparent;
      color: #fff;
      border: none;
      font-family: 'Rubik-Medium';
      margin-right: 5px;
      width: 50%;
      &:focus {
        outline: none;
      }
    }
    .range {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 1px;
      cursor: pointer;
      svg {
        margin-right: 0;
        &:first-child {
          transform: rotate(180deg);
        }
      }
    }
    .quick-action {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 2px;
      color: #a2a3a4;
      width: 36px;
      height: 27px;
      svg {
        min-height: 13px;
      }
      &:hover {
        background-color: #41434a;
      }
    }
  }
  .label {
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    font-family: 'Rubik-Medium';
    margin: 40px 0 20px 0;
  }
  .panel {
    background: #1f1f1f;
    border-radius: 20px;
    width: 100%;
    padding: 10px 20px 20px;
    .tabs {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .tab {
        padding: 30px;
        color: #a2a3a4;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.active {
          color: #ffe036;
          border-bottom: 2px solid #ffe036;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .container {
    .game {
      flex-wrap: wrap;
      .setting {
        width: 100%;
        order: 2;
      }
      .ring {
        order: 1;
        width: 100%;
        min-height: 450px;
        .recent-list {
          right: 0px;
          .result {
            height: 5px;
            margin: 5px 0;
            &.X2 {
              width: 20px;
            }
            &.X3 {
              width: 25px;
            }
            &.X6 {
              width: 30px;
            }
            &.X99 {
              width: 35px;
            }
          }
        }
        .info {
          top: 10px;
          width: 80%;
          .row {
            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
        .volume {
          bottom: 20px;
          right: 0px;
          top: auto;
        }
        .ring-wrapper {
          .total {
            width: 200px;
            height: 200px;
            .second-ring {
              width: 170px;
              height: 170px;
            }
          }
        }
      }
    }
  }
}
.max-profit {
  white-space: nowrap;
  padding: 10px;
  background-color: #212328;
}
