@import '../../variable.scss';
.container {
  padding: 20px;
  background-color: #2c2c2c;
  border-radius: 20px;
}
.title {
  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height: 21px;
  color: #fff;
  text-align: center;
  line-height: 1;
  margin-bottom: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  // align-items: center;
  width: 500px;
  max-height: 730px;
  padding: 5px;
  overflow-y: auto;
  cursor: auto;
  background-color: #2c2c2c;
  .currency-columns {
    text-align: left;
    color: white;
    font-family: 'Rubik-Medium';
    display: flex;
    .icon {
      img {
        width: 18px;
        height: 18px;
      }
      margin-right: 5px;
    }
  }
  .avatar {
    width: 110px;
    height: 110px;
    border: 10px solid #434343;
    border-radius: 20px;
    background-color: #434343;
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: contain;
      overflow: hidden;
    }
  }
  .name {
    font-size: 18px;
    font-family: 'Rubik-Medium';
    margin-top: 20px;
    .level {
      color: #9b9b9b;
    }
  }
  .user-id {
    color: #a2a3a4;
    font-size: 16px;
    font-family: 'Rubik-Regular';
    margin-top: 10px;
    display: flex;
    align-items: center;
    .icon {
      cursor: pointer;
      margin-left: 5px;
    }
  }
  .edit {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    min-width: 40px;
    min-height: 40px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .profile-statictis {
    display: flex;
    margin-bottom: 20px;
    .avatar {
      width: 44px;
      height: 44px;
      border: none;
      margin-right: 10px;
      border-radius: 20px;
      background-color: #434343;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: contain;
        overflow: hidden;
      }
    }
    .name {
      font-size: 18px;
      font-family: 'Rubik-Medium';
      margin-top: 0;
    }
    .user-id {
      color: #a2a3a4;
      font-size: 14px;
      font-family: 'Rubik-Regular';
      margin-top: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .statistics-detail {
    display: flex;
    align-items: center;
    width: 100%;
    .title {
      padding-right: 20px;
      padding-top: 20px;
    }
    .container-select {
      width: 100%;
      .select-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border: none;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 5px;
        padding: 14px 20px;
        cursor: pointer;
        .button-select {
          cursor: pointer;
          width: 100%;
          color: #a2a3a4;
          border: none;
          background: none;
          display: flex;
          justify-content: space-between;
          text-transform: capitalize;
        }
        .select:focus {
          outline: none;
        }
      }
      .option-wrapper {
        background: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        padding: 10px;
        margin-top: 7px;
        width: 100%;
        .option {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 10px;
          border-radius: 5px;
          border: 1px solid transparent;
          cursor: pointer;
          .radio {
            width: 18px;
            height: 18px;
            border-radius: 100%;
            background: transparent;
            border: 1px solid #4a4a4a;
            display: flex;
            align-items: center;
            justify-content: center;
            .content {
              width: 10px;
              height: 10px;
              border-radius: 100%;
              background-color: yellow;
            }
          }
        }
        .option-active {
          border: 1.10938px solid #ffe036;
        }
      }
    }
  }
  .panel {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    padding: 21px;
    width: 100%;
    margin-top: 20px;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-family: 'Rubik-Regular';
      line-height: 19px;

      .statistic-wrapper {
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
        }
      }
      .detail-wrapper {
        color: #ffe036;
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
          margin-left: 15px;
        }
      }
    }
    .statistic-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      width: 100%;
      margin-top: 20px;
      .statistic-block {
        background: #1f1f1f;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 32%;
        height: 91px;
        .title {
          display: flex;
          align-items: center;
          color: #a2a3a4;
          font-family: 'Rubik-Regular';
          font-size: 14px;
          text-transform: capitalize;
          svg {
            margin-right: 5px;
          }
        }
        .data {
          font-family: 'Rubik-Medium';
          font-size: 18px;
          line-height: 21px;
          margin-top: 11px;
        }
      }
    }
    .favorites-game {
      display: flex;
      flex-direction: column;
      align-items: center;
      svg {
        margin-top: 20px;
      }
      .nodata-text {
        color: #a2a3a4;
        font-size: 14px;
        font-family: 'Rubik-Regular';
        margin-top: 16px;
      }
    }
    .header-currency {
      display: flex;
      justify-content: space-between;
      color: #a2a3a4;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .content {
    width: 100%;
    max-height: 100vh;
    height: 100vh;
    padding: 10px;
    overflow-y: auto;
    .panel {
      .statistic-content {
        flex-wrap: wrap;
        justify-content: space-around;
        .statistic-block {
          width: 43%;
          margin: 10px 0;
        }
      }
    }
    .statistics-detail {
      flex-grow: 0;
      flex-direction: column;
      align-items: flex-start;
      .select-wrapper {
        flex-direction: column;
        width: 95vw;
        margin-block: 10px;
        .button-select {
          width: 100%;
          color: #a2a3a4;
          border: none;
          background: none;
          display: flex;
          justify-content: space-between;
        }
        .select:focus {
          outline: none;
        }
      }
    }
  }
}
