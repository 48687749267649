.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 700px;
  width: 100%;
  background: #252525;
  border-radius: 20px;
  padding: 20px;
  img {
    max-width: 500px;
    width: 100%;
  }
  .text {
    margin-top: 25px;
    font-size: 20px;
    line-height: 24px;
    font-family: 'Rubik-Medium';
    span {
      color: yellow;
    }
  }
}
