.dice-animation {
  width: 100%;
  margin-top: 70px;
  .dice-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: calc(100% - 80px);
    margin: 0 auto 20px auto;
    .result {
      background: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
      border-style: solid;
      border-width: 1px;
      border-radius: 53px;
      padding: 10px 40px;
      font-size: 18px;
      font-family: 'Rubik-Medium';
      line-height: 28px;
      margin-bottom: 20px;
    }
    .dice-icon-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .victory-bg {
        z-index: 1;
        position: absolute;
        top: -45%;
        animation: lightSpin 5s linear infinite;
        &.show {
          display: block;
        }
        &.hidden {
          display: none;
        }
      }
      .shadow {
        &.show {
          visibility: visible;
        }
        &.hidden {
          visibility: hidden;
        }
      }
      .dice-icon {
        display: flex;
        align-items: center;
        z-index: 2;
        flex-direction: column;
      }
    }
  }
  .milestone {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 10px 20px;
  }
  .slider-container {
    width: 100%;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    border-radius: 32px;
    padding: 20px;

    text-align: center;
    .slider {
      -webkit-appearance: none;
      width: 95%;
      height: 6px;
      border-radius: 6px;
      outline: none;
      opacity: 0.7;
      -webkit-transition: 0.2s;
      transition: opacity 0.2s;
      &:hover {
        opacity: 1;
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 2px 2px 20px #fff, -2px -2px 20px #fff;
        padding: 3px;
        cursor: pointer;
      }
      &::-moz-range-thumb {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
      }
    }
  }
}

@keyframes lightSpin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
