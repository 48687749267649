@import '../../variable.scss';

@mixin font-style($value) {
  line-height: 45px;
  font-family: 'Rubik-#{$value}';
}

.container {
  display: flex;
  justify-content: space-between;
  position: relative;
  border-radius: $rounded-md;
  background-color: #131313;
  border: 1px solid rgba(255, 255, 255, 0.05);
  margin-top: 25px;
  padding: 30px;
  overflow: clip;

  .coin-1 {
    position: absolute;
    transform: scale(2.5);
    top: 0;
    z-index: 2;
  }

  .coin-2 {
    position: absolute;
    bottom: -35px;
    transform: scale(1.5);
    left: 0;
    z-index: 2;
  }

  .coin-3 {
    position: absolute;
    bottom: 10%;
    right: 5%;
    z-index: 2;
  }

  .coin-4 {
    position: absolute;
    transform: scale(1.5);
    right: -30px;
    top: 50%;
    z-index: 2;
  }

  .coin-5 {
    z-index: 2;
    position: absolute;
    left: 44%;
    top: 50%;
    transform: matrix(0.96, 0, 0.32, 1, 0, 0);
  }

  .coin-6 {
    z-index: 2;
    position: absolute;
    left: 70%;
    top: -10px;
  }

  .affiliate-container {
    max-width: 60%;
    z-index: 3;
    margin-left: 90px;

    .affiliate-wrapper {
      margin: auto;
      background: rgba(255, 255, 255, 0.05);
      backdrop-filter: blur(8px);
      border: 1px solid rgba(255, 255, 255, 0.05);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 20px 25px 30px;
      padding-bottom: 100px;
      align-items: center;

      .star-1 {
        position: absolute;
        transform: scale(1.5);
        left: 20%;
        top: 20px;
      }

      .star-2 {
        position: absolute;
        transform: scale(0.7);
        right: 20%;
        top: 10%;
      }

      .star-3 {
        position: absolute;
        top: 30%;
        right: 10%;
      }

      .gradient-text {
        background: $background-gradient-gold;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
      }

      .title {
        font-size: 32px;
        text-align: center;
        @include font-style('Medium');
      }

      .text {
        padding: 0 35px;
        margin-top: 20px;
        margin-bottom: 25px;

        .text-item {
          font-size: 25px;
          @include font-style('Medium');
          line-height: 29.63px;
        }
      }

      .button {
        background: linear-gradient(90deg, #ffe036 0%, #fbc006 100%);
        border-radius: 20px;
        padding: 10px 30px;
        width: 80%;
        border: 1px solid #ffe036;
        @include font-style('Medium');
        cursor: pointer;
        font-size: 16px;
        line-height: 24px;
      }

      .banner-affiliate-img {
        position: absolute;
        width: 100%;
        border-radius: 25px;
        bottom: 0;
      }

      .description {
        font-size: 18px;
        line-height: 28px;
        margin-top: 10px;
        font-family: 'Rubik-Medium';
        text-align: center;

        .line {
          white-space: nowrap;
        }
      }

      .referral-code {
        font-family: 'Rubik-Regular';
        margin-top: 20px;

        .label {
          font-size: 16px;
          line-height: 24px;
        }

        .code {
          margin-top: 10px;
          background: rgba(255, 255, 255, 0.05);
          border-radius: 5px;
          padding: 5px 5px 5px 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #a2a3a4;
          font-size: 14px;
          line-height: 20px;
          div {
            &:first-child {
              flex: 1;
            }
          }
        }

        svg {
          cursor: pointer;
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  .background-img {
    img {
      position: absolute;
      top: 0;
      height: 100%;
      left: 20%;
      z-index: 1;
    }
  }

  .tag {
    background: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
    height: -webkit-fill-available;
    padding: 12px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.49);
    backdrop-filter: blur(12.5px);
    border-radius: 10px;
    z-index: 3;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 10px;
    }
    .invite-text {
      color: #ffe036;
      font-size: 16px;
    }
  }
}

@media (max-width: $breakpoint-desktop) {
  .container {
    .affiliate-container {
      .affiliate-wrapper {
        .title {
          font-size: 20px;
        }

        .text {
          padding: 0 20px;

          .text-item {
            font-size: 20px;
          }
        }

        .description {
          .line {
            white-space: pre-wrap;
          }
        }
      }
    }

    .background-img {
      img {
        left: 0;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .container {
    flex-direction: column-reverse;
    gap: 10px;

    .affiliate-container {
      max-width: 100%;
      margin-left: inherit;

      .affiliate-wrapper {
        .button {
          padding: 10px 30px;
          line-height: 30px;
          width: 100%;
        }
      }
    }

    .tag {
      margin: auto;
    }
  }
}
