@import '../../../variable.scss';
.container {
  margin: 20px 0;
  background: #181818;
  border-radius: 20px;
  text-align: center;
  padding: 30px 40px;
  .title {
    font-size: 25px;
    line-height: 30px;
    font-family: 'Rubik-Medium';
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    .commission-reward,
    .commission-reward-received {
      display: flex;
      align-items: center;
      margin-top: 20px;

      svg {
        margin-right: 20px;
      }
      .data {
        text-align: left;
        .sub-title {
          color: #a2a3a4;
          font-size: 14px;
          line-height: 20px;
        }
        .value {
          margin-top: 5px;
          background: rgba(255, 255, 255, 0.05);
          border-radius: 5px;
          font-size: 18px;
          line-height: 28px;
          color: #ffe036;
          padding: 5px 20px;
          text-align: center;
        }
      }
    }
    .divider {
      width: 1px;
      background-color: #4a4a4a;
      height: 60px;
      margin-top: 20px;
    }
  }
}
@media screen and (max-width: $breakpoint-mobile) {
  .divider {
    display: none;
  }
}
