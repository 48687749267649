.wrapper {
  width: 26.25rem;
  font-family: 'Rubik-Regular';
  font-size: 14px;
  line-height: 20px;

  .show-again,
  .actions {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 100%;
  }
  .show-again {
    justify-content: flex-start;
    .text {
      margin-left: 10px;
    }
  }
  .actions {
    justify-content: center;
    margin-bottom: 20px;
    .confirm-btn {
      background: linear-gradient(90deg, #ffe036 0%, #fbc006 100%);
      color: #2c2c2c;
    }
    .cancel-btn {
      color: #a2a3a4;
    }
    .action-btn {
      height: 40px;
      margin: 0 10px;
      width: fit-content;
      border: 2px solid #444444;
      border-radius: 35px;
      padding: 10px 40px;
      font-size: 16px;
      line-height: 19px;
      font-family: 'Rubik-Medium';
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
