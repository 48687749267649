@import '../../variable.scss';

.wrapper {
  .header {
    display: flex;
    font-family: 'Rubik-Medium';
    p {
      padding: 0px 5px;
      display: flex;
      align-items: center;
      font-size: 16px;
    }
  }

  .list-navigate-page {
    @include list-default;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    gap: 1.5rem 1.5rem;
    padding: 20px 0px;

    .item {
      position: relative;
      margin: auto;
      .background {
        display: flex;
        img {
          width: 100%;
          border-radius: 20px;
        }
      }
    }
  }
}
.current-popup {
  width: 150px;
  display: flex;
  flex-direction: row;
  img {
    width: 30%;
  }
  .current-popup-content {
    width: 70%;
  }
}
@media screen and (max-width: $breakpoint-mobile) {
  .wrapper {
    .list-navigate-page {
      grid-template-columns: repeat(auto-fill, minmax(30px, 1fr));
      gap: 0.5rem 0.8rem;
    }
  }
}
