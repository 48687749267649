@import './../../variable.scss';

.label {
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  font-family: 'Rubik-Medium';
  margin: 40px 0 20px 0;
}

.panel {
  background: #1f1f1f;
  border-radius: 20px;
  width: 100%;
  padding: 10px 20px 20px;
  .tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .tab {
      font-size: 14px;
      line-height: 17px;
      padding: 30px;
      color: #a2a3a4;
      font-family: 'Rubik-Regular';
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.active {
        color: #ffe036;
        border-bottom: 2px solid #ffe036;
      }
    }
  }
}
