.term-page {
  background: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
  opacity: 0.8;
  border-radius: 10px;
  padding: 30px;
  position: relative;
  .sub-title {
    color: #32ff90;
    margin: 12px 0;
    font-size: 16px;
    font-family: 'Rubik-Medium';
  }
  .content {
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
  }
  .read-more {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 200px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .content {
      cursor: pointer;
      display: flex;
    }
    .text {
      margin-right: 10px;
    }
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 6.6%,
      rgba(0, 0, 0, 0.51) 32.54%,
      rgba(0, 0, 0, 0.98) 70.85%
    );
    border-radius: 0px 0px 10px 10px;
  }
}

.hidden-doc {
  max-height: 826px;
  overflow: hidden;
}

.general-page {
  background: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
  opacity: 0.8;
  border-radius: 10px;
  padding: 30px;
  .sub-title {
    color: #32ff90;
    margin: 12px 0;
    font-family: 'Rubik-Medium';
  }
  .content {
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
  }
}
