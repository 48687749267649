@import '../../variable.scss';

@mixin font-style($value) {
    line-height: 45px;
    font-family: 'Rubik-#{$value}';
}

.container {
    padding-bottom: 40px;

    .title {
        text-align: center;
        margin: 30px;
        font-size: 25px;
    }

    .item {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        .item-content {
            background: #181818;
            border-radius: 20px;
            padding: 30px;
            width: -webkit-fill-available;


            .item-header {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                margin-bottom: 20px;

                .item-label {
                    font-size: 25px;
                    color: #FFE036;
                }


            }

            .item-text {
                font-size: 14px;

                .text {
                    margin-bottom: 20px;
                    color: #969696;

                    span {
                        color: #FFE036;
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint-desktop) {
    .container {
        .item {
            .item-content {
                .item-header {
                    .item-label {
                        font-size: 20px;
                    }

                    .header-img {
                        img {
                            height: auto;
                            width: auto;
                            max-width: 126px;
                            max-height: 145px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .container {
        .item {
            display: flex;
            flex-direction: column;
        }
    }
}