@import '../../variable.scss';
.faq-page {
  background: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
  opacity: 0.8;
  border-radius: 10px;
  padding: 30px;
  .list-faq {
    @include list-default();
    .item {
      padding: 15px 20px;
      border: 1px solid #4a4a4a;
      border-radius: 10px;
      margin-bottom: 10px;
      cursor: pointer;
      transition: 0.5s ease-in-out;
      .question {
        font-size: 16px;
        line-height: 24px;
        color: #32ff90;
        font-family: 'Rubik-Medium';
        display: flex;
        justify-content: space-between;
        align-items: center;
        .subtraction {
          border-top: 1.5px solid #32ff90;
          width: 12px;
          transition: 0.3s ease-in-out;
        }
      }
      .answer {
        overflow: hidden;
        transition: max-height 0.3s ease-out;
        max-height: 0;
        font-size: $font-size-sm;
        a {
          color: yellow;
        }
      }
      .answer-active {
        max-height: 100px;
      }
    }
  }
}
