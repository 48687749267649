.input-amount {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 5px 5px 5px 10px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    margin: 3px 0;
    width: 100%;
    height: fit-content;
    .icon {
      margin-right: 5px;
      display: flex;
      align-items: center;
    }
    input {
      background: transparent;
      color: #fff;
      border: none;
      font-family: 'Rubik-Medium';
      margin-right: 5px;
      width: 50%;
      &:focus {
        outline: none;
      }
    }
    .range {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 1px;
      cursor: pointer;
      svg {
        margin-right: 0;
        &:first-child {
          transform: rotate(180deg);
        }
      }
    }
    .multiplication,
    .division {
      width: fit-content;
      padding: 5px 10px;
      cursor: pointer;
      margin: 0 1px;
    }
    .quick-action {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 2px;
      color: #a2a3a4;
      width: 36px;
      height: 27px;
      svg {
        min-height: 13px;
      }
      &:hover {
        background-color: #41434a;
      }
    }
  }