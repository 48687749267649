@import './../../../variable.scss';

.form-container {
  width: 100%;

  .bet-button {
    width: 100%;
    max-width: 260px;
    height: 68px;
    margin: 0px auto 20px;
    background: linear-gradient(90deg, #ffe036 0%, #fbc006 100%);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: #222222;
    font-weight: 500;

    cursor: pointer;
  }

  .form-input {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    font-size: 14px;
    margin-top: 10px;
    .amount-input-container {
      grid-column: span 1;
      .amount-label {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
        color: $secondary-color;
      }
    }

    .auto-cash-input-container {
      grid-column: span 1;
      .label-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        color: $secondary-color;
        .change-text {
          span {
            color: #ffe036;
          }
        }
      }
    }
  }

  .tokens {
    width: 16px;
    height: 16px;
  }

  .suffix-group {
    display: flex;
    align-items: center;
    gap: 5px;

    .suffix-btn {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 2px;
      padding: 0px 10px;
      font-size: 14px;
      color: $secondary-color;
      height: 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      cursor: pointer;
    }

    .suffix-btn-arrows {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 2px;
      padding: 0px 10px;
      font-size: 14px;
      color: $secondary-color;
      height: 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .suffix-auto-cash-btn {
      line-height: 0px;
      padding: 0px 10px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;
    }
    .arrows {
      line-height: 10px;
      .arrow-btn {
        cursor: pointer;
      }
      .arrow-up {
        transform: rotate(180deg);
      }
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;
      width: 100%;
    }

  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .form-container {
    .form-input {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
