@import '../../variable.scss';
.container {
  border-radius: 20px;
  overflow: hidden;
  max-width: 1350px;
  background-image: url('../../../../public/images/games/game-profile-bg.png');
  background-position: center;
  background-size: contain;
  .bg {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.56) 36.03%, #000000 67.19%);
    padding: 20px;

    width: 100%;
    .info-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .info {
        display: flex;
        font-size: 22px;
        line-height: 26px;
        font-family: 'Rubik-Regular';
        img {
          border-radius: 20px;
          width: 100px;
          height: 100px;
        }
        .name-date-wrapper {
          margin-left: 17px;
          .name {
            color: #ffffff;
            font-size: 22px;
          }
          .provider {
            color: #ffffff;
            font-size: 14px;
            line-height: 20px;
            margin: 10px 0;
            span {
              color: #a2a3a4;
            }
          }
          .release-date {
            color: #a2a3a4;
            font-size: 14px;
          }
        }
      }
      .more-info {
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid #4a4a4a;
        backdrop-filter: blur(10px);
        border-radius: 5px;
        padding: 10px 20px;
        text-align: center;
        color: #a2a3a4;
        font-size: 14px;
        line-height: 17px;
        font-family: 'Rubik-Regular';
        cursor: pointer;
      }
    }
    .game-info {
      margin-top: 20px;
      display: flex;
      align-items: flex-start;

      .title {
        color: #a2a3a4;
        font-family: 'Rubik-Medium';
        font-size: 18px;
        line-height: 28px;
      }
      .info {
        width: 60%;
        .info-content {
          border-radius: 20px;
          overflow: hidden;
          .row {
            display: flex;
            align-items: center;
            .ceil {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 50%;
              background: rgba(255, 255, 255, 0.05);
              margin-right: 2px;
              margin-top: 1px;
              margin-bottom: 1px;
              font-family: 'Rubik-Regular';
              font-size: 14px;
              line-height: 20px;
              padding: 20px;
              &.disable {
                .label,
                .value-number,
                .value-text {
                  color: #4a4a4a;
                }
              }
              .label {
                color: #a2a3a4;
              }
              .value-number {
                color: #ffe036;
              }
              .value-text {
                color: #ffffff;
              }
            }
          }
        }
      }
      .about-game {
        width: 40%;
        margin-left: 40px;
        .content {
          color: #a2a3a4;
          font-size: 12px;
          line-height: 18px;
          margin-top: 10px;
          white-space: pre-line;
          max-height: 300px;
          overflow-y: auto;
        }
      }
    }
    .footer {
      margin-top: 16px;
      .show-more {
        display: flex;
        align-items: center;
        color: #ffe036;
        cursor: pointer;
        svg {
          margin-left: 5px;
        }
        &.show-less {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
@media screen and (max-width: $breakpoint-mobile) {
  .container {
    .bg {
      padding: 5px;

      .info-wrapper {
        .info {
          img {
            border-radius: 20px;
          }
          .name-date-wrapper {
            .name {
              font-size: 18px;
            }
          }
        }
        .more-info {
          flex: 1;
          padding: 5px;
          margin-left: 20px;
        }
      }
      .game-info {
        flex-wrap: wrap;

        .title {
          font-size: 16px;
          line-height: 20px;
        }
        .info {
          width: 100%;
          .info-content {
            .row {
              .ceil {
                padding: 5px 8px;
                font-size: 12px;
              }
            }
          }
        }
        .about-game {
          width: 100%;
          margin-left: 0px;
          margin-top: 20px;
        }
      }
    }
  }
}
