@import '../../variable.scss';

.layout-form {
  display: flex;
}
.banned-warning {
  width: 320px;
  font-size: 12px;
  line-height: 18px;
  color: #e74c3c;
  margin-top: 30px;
  margin-bottom: -30px;
}
.col-form {
  padding: 20px;

  .welcome {
    text-align: center;

    .title {
      font-family: 'Rubik-Medium';
      font-size: $font-size-xl;

      .name {
        color: $text-yellow;
      }
    }

    .desc {
      font-size: $font-size-lg;
    }
  }

  .verification {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .send-verification {
      width: 254px;
      height: 108px;
      object-fit: contain;
    }

    .success-verification {
      margin-top: -40px;
      width: 50px;
      height: 50px;
      object-fit: contain;
      margin-left: 10px;
    }

    .verifycation-complete {
      color: #a2a3a4;
      font-size: 18px;
      margin: 12px 0;
    }

    .congratulation {
      font-size: 14px;
      line-height: 17px;
    }

    .label {
      margin: 22px 0;
    }

    .confirm {
      padding: 10px 48px;
      background: none;
      cursor: pointer;
      border: 2px solid #444444;
      border-radius: 38px;
      color: $secondary-color;
      font-size: 16px;
      font-family: 'Rubik-Medium';
      margin-top: 20px;
    }

    .button-active {
      color: #2c2c2c;
      background: linear-gradient(90deg, #ffe036 0%, #fbc006 100%);
    }
  }

  .form {
    .form-item {
      margin-top: 10px;
      width: 320px;
      position: relative;

      .eye-icon {
        position: absolute;
        cursor: pointer;
        top: 15px;
        right: 10px;
        bottom: 10px;
      }

      .icon {
        position: absolute;
        cursor: pointer;
        right: 10px;
        bottom: 10px;
      }

      .error {
        color: #e74c3c;
        font-size: 12px;
        margin: 8px 0;
        line-height: 18px;
      }

      .form-label {
        font-size: $font-size-sm;
        color: $secondary-color;
        margin-block: 10px;
      }

      .form-label-forgotPwd {
        display: flex;
        justify-content: space-between;

        .forgot-pwd {
          color: #1898df;
          cursor: pointer;
        }
      }
    }

    .form-item-checkbox {
      margin-top: 16px;
      width: 320px;
      display: flex;
      color: $secondary-color;

      .checkbox {
        margin-right: 5px;
        position: relative;
        font-size: 14px;

        input {
          opacity: 0;
          width: 18px;
          height: 18px;
        }

        .custom-checkbox,
        svg {
          background: #444444;
          border-radius: 3px;
          width: 18px;
          height: 18px;
          position: absolute;
          top: 0;
          left: 0;
          cursor: pointer;
        }
      }

      .term-of-use-wrapper {
        font-size: 14px;
      }

      .term-of-use {
        color: #1898df;
        cursor: pointer;
      }
    }

    .form-button {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      div,
      button {
        padding: 10px 48px;
        background: none;
        cursor: pointer;
        border: 2px solid #444444;
        border-radius: 38px;
        color: $secondary-color;
        font-size: 16px;
        font-family: 'Rubik-Medium';
      }

      .button-active {
        color: #2c2c2c;
        background: linear-gradient(90deg, #ffe036 0%, #fbc006 100%);
      }

      .disable {
        pointer-events: none;
        opacity: 0.7;
      }
    }

    .inp-login {
      background-color: #444444;
      width: 100%;
      border-radius: 5px;
      padding: 14px;
      font-size: 16px;
      border: none;
      color: white;
      font-family: 'Rubik-Regular';
    }

    .inp-login:focus {
      outline: none;
    }

    .login-diff {
      margin-top: 60px;
      text-align: center;

      .title {
        font-size: 18px;
      }

      .icons {
        margin-top: 16px;
        display: flex;
        justify-content: center;

        .icon {
          margin-inline: 10px;
          cursor: pointer;

          img {
            width: 40px;
            height: 40px;
            object-fit: contain;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .layout-form {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 70vh;
    width: 97%;

    .col-image {
      width: 50%;
      height: auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
