@import '../../variable.scss';
.title {
  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height: 21px;
  color: white;
}

.content {
  max-width: 420px;
  width: 100%;
  .notice {
    font-size: $font-size-sm;
    padding: 10px 20px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .note-qr {
    text-align: center;
    font-size: $font-size-sm;
    margin-bottom: 20px;
  }
  .address-qr {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    .content {
      background-color: white;
      border: 10px solid #3b3b3b;
      border-radius: 10px;
      width: 145px;
      height: 145px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .select-key {
    margin-bottom: 20px;
    .title-key {
      color: #a2a3a4;
      margin-bottom: 12px;
    }
    .input-key {
      display: flex;
      background: #1f1f1f;
      border-radius: 5px;
      align-items: center;
      padding: 0 20px;
      input {
        color: white;
        font-size: 16px;
        background: transparent;
        border: none;
        width: 100%;
        padding: 20px 0;
      }
      input:focus {
        outline: none;
      }
    }
  }

  .note-key {
    font-size: $font-size-sm;
    color: #a2a3a4;
    margin-bottom: 20px;
  }

  .identity {
    margin-top: 40px;
    border-top: 1px solid #a2a3a4;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin-block: 20px;
      color: #a2a3a4;
      font-family: 'Rubik-Regular';
    }
    .input-code {
      margin-bottom: 20px;
    }
    .input-password {
      width: 100%;
      display: flex;
      margin-bottom: 20px;
      align-items: center;
      background: rgba(255, 255, 255, 0.05);
      border: 1.10938px solid #4a4a4a;
      padding: 0 20px;
      input {
        width: 100%;
        padding: 20px 0px;
        background: transparent;
        border: none;
        color: white;
      }
      input:focus {
        outline: none;
      }
    }
  }
  .button-enable {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    button {
      background: linear-gradient(90deg, #ffe036 0%, #fbc006 100%);
      border-radius: 28px;
      padding: 15px 80px;
      font-size: 16px;
      border: none;
      font-family: 'Rubik-Medium';
      cursor: pointer;
    }
  }
}
