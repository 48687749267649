@import '../../variable.scss';
.title {
  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height: 21px;
  color: #fff;
}
.wrapper {
  max-width: 500px;
}
.title-detail {
  display: flex;
  .icon {
    cursor: pointer;
    margin-right: 10px;
  }
}
