@import '../../variable.scss';
.support-container {
  width: 100%;
  height: 796px;
  border-radius: 20px;
  position: relative;
  background-color: rgb(27, 26, 26);
  .background {
    position: absolute;
    top: 0;
    @include background-image('support-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    mix-blend-mode: lighten;
    filter: blur(5px) grayscale(1);
    border-radius: 20px;
    z-index: 0;
  }
  .content {
    position: absolute;
    top: 0;
    padding: 76px;
    .logo {
      max-width: 241px;
      margin-bottom: 40px;
      img {
        width: 100%;
      }
    }
    .thanks {
      max-width: 400px;
      color: #ffe036;
      line-height: 24px;
      margin-bottom: 20px;
    }
    .contact {
      display: flex;
      margin-bottom: 10px;
      .icon {
        margin-right: 10px;
      }
      .document {
        .title {
          color: #a2a3a4;
        }
        .desc {
          color: #ffe036;
          a {
            text-decoration: none;
            color: #ffe036;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .support-container {
    .content {
      position: static;
      padding: 10px;
    }
  }
}
