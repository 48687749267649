@import '../../variable.scss';

@mixin font-style($value) {
    line-height: 45px;
    font-family: 'Rubik-#{$value}';
}

.container {
    .message {
        display: flex;
        justify-content: center;
        gap: 20px;
        background: #181818;
        border-radius: 20px;
        padding: 30px;
        margin: 30px 0;
        align-items: center;

        .text {
            color: #A2A3A4;
            font-size: 14px;

            span {
                text-decoration: underline;
                color: #FFE036;
            }
        }


    }
}