@import '../variable.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  font-family: 'Roboto';

  .header {
    border-bottom: 1px solid rgb(179, 179, 179, 0.1);
    padding: 18px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dropdown {
      position: relative;

      .menu-dropdown {
        max-height: 0;
        overflow: hidden;
        position: absolute;
        transition: max-height 0.5s ease-out, opacity 0.1s ease-out;
        opacity: 0;
        background-color: $background-color;
        width: 230px;
        border-radius: 20px;
      }
    }

    // .dropdown:hover {
    //   .button-dropdown {
    //     .icon {
    //       transform: rotate(-90deg);
    //       transition: 0.1s ease-out;
    //     }
    //   }
    // }

    .dropdown:hover {
      .menu-dropdown {
        max-height: 700px;
        opacity: 1;
      }
    }

    .header-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      .info-icon:hover {
        cursor: pointer;

        svg {
          path {
            fill: #ffffff;
          }
        }
      }

      .close-icon:hover {
        cursor: pointer;

        svg {
          path {
            fill: #ffffff;
          }
        }
      }
    }

    .button-dropdown {
      padding: 10px 18px;
      border-radius: 34px;
      width: 112px;
      background: rgba(255, 255, 255, 0.05);
      color: white;
      border: none;
      font-size: 1rem;
      font-family: 'Rubik-Medium';
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .list-location {
      @include list-default;
      padding: 10px;

      .item {
        padding: 10px 20px;
        border-radius: 10px;
      }

      .item:hover {
        background: linear-gradient(
          65.58deg,
          rgba(22, 22, 22, 0.5) -11.84%,
          rgba(102, 80, 0, 0.5) 98.75%
        );
        cursor: pointer;
      }
    }
  }

  .list-chat {
    flex-grow: 1;
    padding: 18px 20px;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: $font-size-sm;

    .item {
      display: flex;
      margin-bottom: 20px;

      .avatar {
        width: 40px;
        height: 40px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 99px;
        }
      }

      .user-chat {
        margin-left: 10px;
        margin-right: 0px;

        .info {
          margin-bottom: 5px;
          font-size: $font-size-sm;
          display: flex;
          max-width: 175px;
          flex-wrap: wrap;

          .name {
            margin-left: 0px;
            margin-right: 10px;
            max-width: 175px;
            overflow-wrap: break-word;
          }

          .time {
            color: #535353;
          }
        }

        .giphy-content {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 130px;
          max-width: 150px;
          background: rgba(255, 255, 255, 0.05);
          padding: 10px;
          border-radius: 10px;
        }

        .content {
          max-width: 150px;
          overflow-wrap: break-word;
          background: rgba(255, 255, 255, 0.05);
          padding: 10px 16px;
          border-radius: 20px;
        }
      }
    }

    .my-message {
      flex-direction: row-reverse;

      .user-chat {
        margin-right: 10px;
        margin-left: 0px;

        .info {
          flex-direction: row-reverse;

          .name {
            margin-left: 10px;
            margin-right: 0px;
            text-align: end;
          }
        }

        .giphy-content {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 130px;
          max-width: 150px;
          background: linear-gradient(270deg, #147fbb 0%, #36add2 101.6%);
          padding: 10px;
          border-radius: 10px;
        }

        .content {
          max-width: 150px;
          overflow-wrap: break-word;
          background: rgba(255, 255, 255, 0.05);
          padding: 10px 16px;
          border-radius: 20px;
          background: linear-gradient(270deg, #147fbb 0%, #36add2 101.6%);
        }
      }
    }
  }

  .message-box {
    border-radius: 20px 20px 0px 0px;
    bottom: 0;
    width: 100%;
    padding: 20px 20px 52px;
    background-color: rgba(255, 255, 255, 0.05);

    position: relative;

    .unread-count-container {
      position: absolute;
      top: -32px;
      color: #2b2b2b;
      font-size: 14px;
      width: 36px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      background: url('../../../public/images/unread-count-chat-bg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;

      p {
        margin-top: -5px;
      }
    }

    .chat-form {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .box-chat {
        background: rgba(255, 255, 255, 0.05);
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 0 0 100%;
        transition: 0.5s ease-in-out;

        .icon {
          margin-right: 10px;
          margin-top: 5px;
          cursor: pointer;
        }

        .active {
          svg {
            path {
              fill: #fbc006;
            }
          }
        }

        input {
          border: none;
          background: none;
          color: white;
          width: 100%;
          padding: 15px 20px;
        }

        input:focus {
          outline: none;
        }
      }

      .giphy-picker-container {
        position: fixed;
        z-index: 90;
        bottom: 8.5rem;
        right: 1rem;
      }

      .emoji-picker {
        position: fixed;
        z-index: 90;
        bottom: 8.5rem;
        right: 1rem;
      }

      .is-show-send {
        flex: 0 0 80%;
        transition: 0.5s ease-in-out;

        input {
          width: 80%;
        }
      }

      .send-button {
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
      }

      .send-hidden {
        transform: translateX(150%) rotate(360deg);
        transition: 0.5s ease-in-out;
      }

      .send-block {
        transform: translateX(0) rotate(0deg);
        transition: 0.5s ease-in-out;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .list-chat {
    .item {
      .user-chat {
        .content {
          max-width: 250px !important;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
