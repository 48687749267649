@import '../../../variable.scss';
.container {
  padding: 30px;
  background: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
  border: 8px solid rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  .reward {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-left: 5px;
      width: 28px;
      height: 28px;
      object-fit: contain;
    }
  }
  .result-type {
    font-family: 'Rubik-Medium';
    font-size: 25px;
    line-height: 30px;
    color: #ffffff;
  }
}

.reward-animation {
  animation: cash 2s ease-in-out;
  position: absolute;
  bottom: 0px;
  top: 0;
  opacity: 0;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  height: fit-content;
}
@keyframes cash {
  0% {
    opacity: 0;
    transform: scale(0);
    bottom: -10px;
  }
  50% {
    opacity: 1;
    transform: scale(1);
    bottom: 0px;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    bottom: 0px;
  }
}
@media screen and (max-width: $breakpoint-mobile) {
  .container {
    padding: 10px;
    background: linear-gradient(262.28deg, #1f1f1f 1.13%, #2c2c2c 49.35%, #252525 97.58%);
    border: 8px solid rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    .reward {
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        font-size: 16px;
      }
      img {
        margin-left: 5px;
        width: 16px;
        height: 16px;
        object-fit: contain;
      }
    }
    .result-type {
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
    }
  }
}
