@import '../variable.scss';
a {
  color: $secondary-color;
  text-decoration: none;
}
.contact-us {
  p {
    font-weight: 500;
    line-height: 30px;
  }
  a {
    font-weight: 500;
    line-height: 30px;
  }
  .contact-us-text {
    font-weight: 300;
  }
  .footer-link-support {
    font-weight: 300;
  }
}
.footer-root {
  background-color: $background-color-primary;
  width: 100%;
  color: $secondary-color;
  margin-top: 25px;
  border-radius: 20px 20px 0px 0px;
  line-height: 24px;

  .footer-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    font-size: 16px;

    .footer-row {
      display: flex;
      align-items: center;
      .footer-link-support {
        cursor: pointer;
        font-size: 14px;
      }
      .contact-us-text {
        margin-left: 10px;
        font-size: 14px;

        .footer-link {
          color: #ffe03680;
          cursor: pointer;
          font-size: 14px;
        }

        .footer-link:hover {
          text-decoration: underline;
        }
      }
    }

    .social-networks-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 50px;
      p {
        color: #7f7f7f;
        font-weight: 400;
      }
    }
  }
}

.footer-root {
  position: relative;
  overflow: clip;

  .scoin-1 {
    position: absolute;
    z-index: 2;
    top: 10%;
    right: 30%;
  }

  .scoin-2 {
    filter: blur(1px);
    transform: scale(2);
    top: 40%;
    z-index: 2;
    position: absolute;
  }

  .scoin-3 {
    position: absolute;
    top: -20px;
    left: 5%;
  }

  .scoin-4 {
    transform: rotate(-70deg);
    position: absolute;
    top: 50%;
    left: 35%;
  }

  .scoin-5 {
    position: absolute;
    top: -20px;
    transform: rotate(-100deg);
    left: 45%;
  }

  .scoin-6 {
    position: absolute;
    bottom: -40px;
    right: 0;
    transform: scale(2);
    filter: blur(2px);
  }

  .scoin-7 {
    left: 50%;
    position: absolute;
    bottom: -20px;
    transform: rotate(-90deg);
  }

  .footer-container-affiliate {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    font-size: 12px;
  }

  .footer-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 72px;

    .slogan {
      z-index: 3;

      .text {
        font-size: 20px;
        color: #ffe036;
        font-weight: 400;
        line-height: 28px;
      }

      span {
        font-size: 32px;
        color: #ffffff;
        font-weight: 500;
        line-height: 38px;
      }

      .slogan-text {
        margin: 20px 39px;
      }
    }
  }

  .footer-infor {
    z-index: 4;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .footer-row {
      display: flex;
      align-items: center;

      .contact-us-text {
        margin-left: 10px;

        .footer-link {
          color: #ffe03680;
          cursor: pointer;
        }

        .footer-link:hover {
          text-decoration: underline;
        }
      }
    }

    .social-networks-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}

@media screen and (max-width: $breakpoint-desktop) {
  .footer-root {
    .footer-header {
      display: flex;
      justify-content: center;
      padding: 0;

      .image-people-jump {
        display: none;
      }

      .image-friends {
        display: none;
      }

      .slogan {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding-bottom: 10px;

        .text {
          font-size: 18px;
        }

        span {
          font-size: 23px;
        }

        .slogan-text {
          margin: 0;
        }
      }
    }

    .footer-infor {
      flex-direction: column;

      .contact-us {
        margin-bottom: 10px;
        text-align: center;
      }

      .accepted-currencies {
        margin-bottom: 10px;
      }

      .social-networks-container {
        flex-direction: column;
        align-items: center;
      }
    }

    .scoin-1 {
      right: 0;
    }

    .scoin-4 {
      left: 80%;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .footer-root {
    margin-bottom: 70px;
    padding: 10px;

    .footer-container {
      flex-direction: column;
      justify-content: center;
      padding: 0px;
      text-align: center;

      .contact-us {
        margin-bottom: 10px;

        .footer-row {
          justify-content: center;
        }
      }

      .accepted-currencies {
        margin-bottom: 10px;
      }

      .social-networks-container {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
}

@media screen and (max-width: $breakpoint-desktop) {
}
