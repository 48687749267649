@import '../../variable.scss';

@mixin font-style($value) {
  line-height: 45px;
  font-family: 'Rubik-#{$value}';
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    width: 100%;

    .rewards-block {
      padding: 14px 24px;
      width: fit-content;
      margin: 60px auto 0 auto;
      background: url('../../../../public/images/background-text.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      color: #ffffff;
      font-size: 18px;
      line-height: 28px;
      font-family: 'Rubik-Medium';

      svg {
        margin-right: 10px;
      }
    }
  }

  .arrow {
    margin: auto;
    width: 50%;
  }

  .item {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;

    .item-content {
      background: #181818;
      border-radius: 20px;
      width: -webkit-fill-available;

      .header {
        background-color: #1f1f1f;
        border-radius: 20px 20px 0 0;
        height: 22%;

        .item-header {
          display: flex;
          justify-content: space-between;
          gap: 10px;
          padding: 60px 20px;
          align-items: center;

          .header-left {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .header-title {
              font-size: 25px;
              color: #ffe036;
              line-height: 30px;
            }

            .header-text {
              font-size: 14px;
              line-height: 20px;

              .text {
                color: #ffe036;
                margin-bottom: 10px;
              }
            }
          }
        }
      }

      .item-body {
        padding: 40px 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .item-body-commision {
        padding: 50px 30px;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: clip;
        gap: 10px;

        .scoin-1 {
          position: absolute;
          z-index: 2;
          top: 10%;
          right: 10%;
        }

        .scoin-2 {
          transform: scale(2);
          top: 40%;
          z-index: 2;
          position: absolute;
        }

        .scoin-3 {
          position: absolute;
          left: 5%;
        }

        .scoin-4 {
          transform: rotate(-70deg);
          position: absolute;
          top: 40%;
          right: 30%;
        }

        .scoin-5 {
          position: absolute;
          top: -20px;
          transform: rotate(-100deg);
          left: 45%;
        }

        .scoin-6 {
          position: absolute;
          bottom: 30%;
          right: -20px;
          transform: scale(1.5);
          filter: blur(1px);

        }

        .scoin-7 {
          left: -15px;
          position: absolute;
          bottom: 20%;
          transform: rotate(-90deg);

        }
      }

      .chart-text-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 30px;
        padding: 30px;

        .chart-text {
          font-size: 25px;
          color: #32ff90;
          font-family: 'Rubik-Medium';
        }

        text {
          color: white;
          font-size: 18px;
        }
      }

      .body-text {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #A2A3A4;
        padding: 0 80px 30px 80px;

      }

      .chart {
        padding: 70px 0;
        margin: auto;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          max-width: 100%;
        }

        .chart-text-bankroll-item {
          position: absolute;
          display: flex;
          bottom: 70px;
          right: 60%;
          flex-direction: column;

          .chart-text-bankroll {
            font-size: 16px;
            color: #667bff;
          }

          text {
            color: white;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .container {
    .title {
      padding: 20px;

      .text-item {
        left: 18%;
      }

      .background-text {
        left: 10%;
      }
    }

    .arrow {
      display: none;
    }

    .item {
      display: flex;
      flex-direction: column;

      .item-content {
        .item-body {
          .chart {
            img {
              height: auto;
              width: auto;
              max-width: 280px;
              max-height: 345px;
            }

            .chart-text-item {
              top: -30px;
            }
          }
        }

        .item-body-commision {
          .scoin-4 {
            right: 0;
          }
        }
      }
    }
  }
}